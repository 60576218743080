import React from 'react';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Typography,
  Breadcrumbs,
  Link,
} from '@mui/material';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {useMediaQuery, useTheme} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';

import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';

import {useOnboarding} from '../Onboarding/OnboardingProvider';

const ProKeyDuties = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const {onboardingState} = useOnboarding();
  const {stages} = onboardingState;
  const stageIcons = [LooksOneIcon, LooksTwoIcon, Looks3Icon, Looks4Icon, Looks5Icon, Looks6Icon];
  return (
    <Container sx={{py: 4}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 4}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Typography color="text.primary"> Key Duties</Typography>
      </Breadcrumbs>
      <Typography variant="h2" textAlign="center" gutterBottom>
        Key Duties
      </Typography>
      <Typography textAlign="center" paragraph>
        Select a stage to view the duties for your profession within that stage.
      </Typography>
      <Typography variant="body2" textAlign="center" paragraph>
        The stage descriptions are the same as those used in Compass for Parents.
      </Typography>
      <Timeline
        position={isLg ? 'alternate' : 'right'}
        //https://mui.com/material-ui/react-timeline/#left-aligned-with-no-opposite-content
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}>
        {stages?.map((stage, index) => (
          <TimelineItem key={stage.id}>
            {isLg && (
              <TimelineOppositeContent
                sx={{m: 'auto 0'}}
                align="right"
                variant="body2"
                color="text.secondary">
                {stage.medium_label}
              </TimelineOppositeContent>
            )}
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary">{React.createElement(stageIcons[index])}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{py: '12px', px: 2}}>
              <Card>
                <CardActionArea onClick={() => navigate(`stage/${stage.id}`)}>
                  <CardHeader title={stage.name} subheader={stage.medium_label} />
                  <CardContent>
                    <Typography variant="body2">{stage.description}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Container>
  );
};

export default ProKeyDuties;
