import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Container, Typography, Box, Button, Divider, Link} from '@mui/material';

import LandingVisual from '../img/landing-page.svg';
import whoIsCompassForIcon from '../img/tell-me-more-landing.svg';
import {sectionIcons} from '../Util/constants';
import LandingPageFeature from '../LandingPageFeature';

const ProLandingPage = () => {
  const {transitionTopicsIcon, feedbackIcon, glossaryIcon} = sectionIcons;
  return (
    <>
      <Container maxWidth="md" sx={{paddingTop: {md: 5, xs: 0}}}>
        <Box display="flex" flexDirection={{xs: 'column', md: 'row-reverse'}}>
          <img src={LandingVisual} alt="" height="360px" />
          <Box sx={{mb: {xs: 2}}}>
            <Typography variant="h1" sx={{mb: 2}}>
              Compass for Professionals
            </Typography>
            <Typography variant="h3" sx={{mb: 2}}>
              Compass Pro helps you support young people, and their parents, as they navigate the
              complex process of transitioning to adulthood.
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to="/register"
                sx={{mx: 1}}>
                Sign up
              </Button>
              <Button variant="outlined" sx={{mx: 1}} component={RouterLink} to="/login">
                Log In
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider aria-hidden="true" sx={{my: 4, backgroundColor: 'primary.pale', width: '100%'}} />
      </Container>
      <Container maxWidth="md" sx={{py: 4}}>
        <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
          <Box component="img" src={whoIsCompassForIcon} alt="" sx={{height: {xs: 80, md: 120}}} />
          <Typography variant="h2">Who is Compass Pro for?</Typography>
        </Box>
        <Typography paragraph>
          Compass Pro is for professionals working with young people (and their parents) who have
          additional support needs. It provides support and guidance to help you support them as
          they navigate the complex process of transitioning to adulthood.
        </Typography>
        <Divider aria-hidden="true" sx={{mt: 6, backgroundColor: 'primary.pale', width: '100%'}} />
      </Container>
      <Container maxWidth="md" sx={{py: 4}}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Typography variant="h2" gutterBottom>
            Why Compass?
          </Typography>
          <LandingPageFeature
            iconSrc={transitionTopicsIcon}
            title="Transition Topics"
            description="Access a range of curated topics covering all the key areas of support young people and their parents may need, including education, employment, finance, health and wellbeing."
          />
          <LandingPageFeature
            iconSrc={glossaryIcon}
            title="Resources"
            description="Compass Pro also includes links to helpful resources for each topic to help you stay up-to-date on the latest best-practices and legislation."
          />
          <LandingPageFeature
            iconSrc={feedbackIcon}
            title="Feedback"
            description="Compass Pro provides a way for you to share your thoughts and suggestions on how we can improve Principles into Practice and Compass."
          />
        </Box>
      </Container>
      <Container maxWidth="md" sx={{py: 4}}>
        <Divider aria-hidden="true" sx={{mb: 4, backgroundColor: 'primary.pale'}} />
        <Typography paragraph>
          View Compass{` `}
          <Link component={RouterLink} to="/privacy" target="_blank" rel="noreferrer">
            Privacy Notice
          </Link>
        </Typography>
      </Container>
    </>
  );
};

export default ProLandingPage;
