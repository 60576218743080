import React from 'react';
import {useParams, Link as RouterLink} from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Chip,
  List,
  ListItem,
  Link,
  Breadcrumbs,
} from '@mui/material';

import useKeyDuties from '../Queries/useKeyDuties';
import {useOnboarding} from '../Onboarding/OnboardingProvider';

const ProViewKeyDuty = () => {
  const {data: keyDuties, isLoading, error} = useKeyDuties();
  const {onboardingState} = useOnboarding();
  const {stageId, keyDutyId} = useParams();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error</div>;
  }

  const keyDuty = keyDuties.find((keyDuty) => keyDuty.id === parseInt(keyDutyId));

  // key duty contains keyDuty.link1_title, keyDuty.link1_url, keyDuty.link2_title, keyDuty.link2_url, etc. up to 3 links
  // create an array of links to map over for display
  const links = [];
  for (let i = 1; i <= 3; i++) {
    const linkTitle = keyDuty[`link${i}_title`];
    const linkUrl = keyDuty[`link${i}_url`];
    if (linkTitle && linkUrl) {
      links.push({title: linkTitle, url: linkUrl});
    }
  }

  const stage = onboardingState?.stages?.find((stage) => stage.id === parseInt(stageId));

  return (
    <Container sx={{mt: 2}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to="/key-duties">
          Key Duties
        </Link>
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to={`/key-duties/stage/${stage.id}`}>
          {stage.medium_label}
        </Link>
        <Typography color="text.primary">{keyDuty.title}</Typography>
      </Breadcrumbs>
      <Box display="flex" justifyContent="center">
        <Card sx={{p: 4, maxWidth: '80%'}}>
          <CardHeader
            title={keyDuty.title}
            subheader={
              <Chip
                sx={{mt: 2}}
                label={keyDuty.obligation}
                variant={keyDuty.obligation === 'Must (duty)' ? 'filled' : 'outlined'}
                color={keyDuty.obligation === 'Must (duty)' ? 'secondary' : 'primary'}
              />
            }
          />
          <CardContent>
            <Typography>{keyDuty.description}</Typography>
          </CardContent>
          <CardActions>
            <List>
              {links.map((link, index) => (
                <ListItem key={`link-${index}`}>
                  <Link href={link.url} target="_blank" rel="noreferrer">
                    {link.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default ProViewKeyDuty;
