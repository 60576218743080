import {useMutation, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';

export default function useEvaluationSaveRatings(evaluationId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (responses) => axios.post('/api/evaluation/response', {responses}),
    onSuccess: () => {
      queryClient.invalidateQueries(`evaluation-questions-${evaluationId}`);
    },
  });
}
