import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GavelIcon from '@mui/icons-material/Gavel';
import {Link} from 'react-router-dom';
import {Box} from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import {Container} from '@mui/material';

import useDialogQuery from '../Util/useDialogQuery';
import {sectionIcons, DIALOG_URL_PARAMS} from '../Util/constants';
import {useAuth} from '../Auth/AuthProvider';
import professionalLogo from '../img/logo/logo_professional.svg';
import professionalLogoDark from '../img/logo/logo_professional_dark.svg';
import TransitionTopicsIcon from '../img/MenuIcons/TransitionTopics.svg';
import AccountIcon from '../img/MenuIcons/Account.svg';
import ContactUsIcon from '../img/MenuIcons/ContactUs.svg';
import PrivacyIcon from '../img/MenuIcons/Privacy.svg';
import FeedbackParent from '../img/MenuIcons/Feedback-parent.svg';
import {useDarkModeTheme} from '../DarkModeThemeProvider';

const navIconStyle = {display: 'flex', flexDirection: 'column', mr: 1};

const ProNavBar = () => {
  const {authState, showLogoutDialog} = useAuth();
  const {isDarkMode, setIsDarkMode} = useDarkModeTheme();
  const {openDialog} = useDialogQuery();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerIsOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerIsOpen(false);
  };

  const navigate = useNavigate();

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Container maxWidth="md">
          <Toolbar disableGutters>
            {authState.isAuthenticated && (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  component={Link}
                  to="/"
                  sx={navIconStyle}
                  id="home-button">
                  <HomeIcon />
                  <div style={{fontSize: 11, letterSpacing: 0.33}}>Home</div>
                </IconButton>
                <Box sx={{flex: 1}}>
                  <IconButton to="/" component={Link}>
                    <img
                      src={isDarkMode ? professionalLogoDark : professionalLogo}
                      id="compass-nav-logo"
                      height={25}
                      alt="Compass Homepage"
                    />
                  </IconButton>
                </Box>
                <IconButton
                  id="glossary-nav-button"
                  edge="start"
                  color="inherit"
                  onClick={() => openDialog(DIALOG_URL_PARAMS.GLOSSARY)}
                  sx={navIconStyle}>
                  <img src={sectionIcons.glossaryIcon} height={24} alt="" />
                  <div style={{fontSize: 11, letterSpacing: 0.33}}>Transitions A-Z</div>
                </IconButton>
                <IconButton
                  id="menu-nav-button"
                  edge="start"
                  color="inherit"
                  onClick={handleDrawerOpen}
                  sx={navIconStyle}>
                  <MenuIcon />
                  <div style={{fontSize: 11, letterSpacing: 0.33}}>Menu</div>
                </IconButton>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor={'right'} open={drawerIsOpen} onClose={handleDrawerClose}>
        <div role="presentation" onClick={handleDrawerClose} style={{minWidth: 250}}>
          <div className="nav-bar-header">
            <h2 className="header-4">Menu</h2>
            <IconButton onClick={() => setIsDarkMode(!isDarkMode)} sx={{ml: 'auto'}}>
              <Brightness4Icon />
            </IconButton>
          </div>
          <Divider aria-hidden="true" />
          <List>
            <ListItemButton
              onClick={() => {
                navigate('/');
                handleDrawerClose();
              }}>
              <ListItemIcon>
                <HomeIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={'Home'} />
            </ListItemButton>
            <ListItemButton
              data-testid="professionals-nav-button"
              onClick={() => {
                navigate('/key-duties');
                handleDrawerClose();
              }}>
              <ListItemIcon>
                <GavelIcon sx={{color: 'primary.dark'}} />
              </ListItemIcon>
              <ListItemText primary={'Professionals'} />
            </ListItemButton>
            <ListItemButton
              data-testid="parents-nav-button"
              onClick={() => {
                navigate('/parent-categories');
                handleDrawerClose();
              }}>
              <ListItemIcon>
                <SupervisorAccountIcon sx={{color: 'primary.dark'}} />
              </ListItemIcon>
              <ListItemText primary={'Parents'} />
            </ListItemButton>
            <ListItemButton
              data-testid="young-people-nav-button"
              onClick={() => {
                navigate('/young-person-categories');
                handleDrawerClose();
              }}>
              <ListItemIcon>
                <Diversity3Icon sx={{color: 'primary.dark'}} />
              </ListItemIcon>
              <ListItemText primary={'Young People'} />
            </ListItemButton>
            <ListItemButton
              data-testid="transition-timeline-nav-button"
              onClick={() => {
                navigate('/timeline');
                handleDrawerClose();
              }}>
              <ListItemIcon>
                <img src={TransitionTopicsIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary={'Transition Timeline'} />
            </ListItemButton>
            <ListItemButton
              data-testid="feedback-nav-button"
              onClick={() => {
                navigate('/feedback');
                handleDrawerClose();
              }}>
              <ListItemIcon>
                <img src={FeedbackParent} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary={'Feedback'} />
            </ListItemButton>
            <ListItemButton
              data-testid="my-account-nav-button"
              onClick={() => {
                navigate('/my-account');
                handleDrawerClose();
              }}>
              <ListItemIcon>
                <img src={AccountIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary={'My Account'} />
            </ListItemButton>
            <ListItemButton
              data-testid="privacy-nav-button"
              onClick={() => {
                navigate('/privacy');
                handleDrawerClose();
              }}>
              <ListItemIcon>
                <img src={PrivacyIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary={'Privacy Policy'} />
            </ListItemButton>
            <ListItemButton
              data-testid="contact-us-nav-button"
              onClick={() => {
                navigate('/contact-us');
                handleDrawerClose();
              }}>
              <ListItemIcon>
                <img src={ContactUsIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary={'Contact Us'} />
            </ListItemButton>
            <ListItemButton
              data-testid="logout-nav-button"
              onClick={() => {
                handleDrawerClose();
                showLogoutDialog();
              }}>
              <ListItemIcon>
                <LogoutIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default ProNavBar;
