import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useEvaluationQuestions = (evaluationId, args) => {
  const {data, isLoading, error} = useQuery({
    queryKey: [`evaluation-questions-${evaluationId}`],
    queryFn: async () => await axios.get('/api/evaluation/questions').then((res) => res.data),
    enabled: !!evaluationId,
    ...args,
  });

  return {data, isLoading, error};
};

export default useEvaluationQuestions;
