import React from 'react';
import {Card, CardActions, CardContent, CardHeader, Skeleton} from '@mui/material';

const LoadingCard = () => {
  return (
    <Card>
      <CardHeader
        avatar={<Skeleton variant="circular" width={40} height={40} />}
        title={<Skeleton variant="text" />}
      />
      <CardContent>
        <Skeleton variant="text" />
      </CardContent>
      <CardActions>
        <Skeleton variant="rectangle" />
      </CardActions>
    </Card>
  );
};

export default LoadingCard;
