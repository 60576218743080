import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Breadcrumbs, Link, Container, Typography} from '@mui/material';

import useYoungPersonCategories from '../Queries/useYoungPersonCategories';
import ProCategories from './ProCategories';
import {YOUNG_PERSON_CATEGORY_ICONS} from '../Util/constants';

const ProYoungPersonCategories = () => {
  const {data: categories, isLoading, error} = useYoungPersonCategories();
  const categoriesWithIcons =
    categories?.map((category) => ({
      ...category,
      icon: YOUNG_PERSON_CATEGORY_ICONS[category.key],
    })) ?? [];

  return (
    <Container sx={{mt: 2}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Typography color="text.primary">Young People</Typography>
      </Breadcrumbs>
      <Typography variant="h2" textAlign="center" gutterBottom>
        Young People
      </Typography>
      <ProCategories categories={categoriesWithIcons} isLoading={isLoading} error={error} />
    </Container>
  );
};

export default ProYoungPersonCategories;
