import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';

import PageHeader from '../Util/PageHeader';
import PrivacyHeaderIcon from '../img/privacy-header.svg';

const PrivacyListItem = ({text}) => (
  <ListItem>
    <ListItemIcon>
      <CircleIcon sx={{fontSize: 'small'}} />
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);

function createData(name, description, isShared) {
  return {name, description, isShared};
}

const typesRows = [
  createData(
    'Account information',
    'When you use Compass, you create a profile which includes your username and email address. This allows you to access your profile and to use Compass. We will send you password reminders or other essential account information by email. We will send you reminders by email or SMS if you ask us to.',
    'Never'
  ),
  createData(
    'Contact information',
    'When you provide us with contact information we will use it to send you information about the training courses that we run. You will be able to choose how we contact you: by email or SMS. We will always provide an easy way to opt out of receiving those messages.',
    'Only internally, within ARC'
  ),
  createData(
    'Professional profile',
    'We will ask you to provide your job title, the sector or industry that you work in and the geographical area where you work.',
    'Some of this information will be aggregated and shared in an anonymous way with the local authority area/s where you work.'
  ),
  createData(
    'Transition information',
    'We will ask you questions about your experience of transition for young people.',
    'This will be shared in an anonymous and aggregated form.'
  ),
];

const lawfulBasisRows = [
  createData(
    'Contractual Obligation',
    'We process Account Information to provide you with a user account and we ask questions about you so that we can ensure you get the relevant information.'
  ),
  createData(
    'Legitimate Interest',
    'We are using the responses you provide to our questions about the transition process and your evaluation of that so we can work with other organisations to improve the journey. We believe that this a good reason for us to ask personal questions but we have balanced this against your privacy interests. Therefore, we only share aggregated and anonymised information.'
  ),
];

const rightsRows = [
  createData(
    'Request access to your personal information',
    'Commonly known as a "data subject access request". This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.'
  ),
  createData(
    'Request correction of the personal information that we hold about you',
    'This enables you to have any incomplete or inaccurate information we hold about you corrected.'
  ),
  createData(
    'Request erasure of your personal information',
    'This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).'
  ),
  createData(
    'Request the restriction of processing of your personal information',
    'You would make this request if, for example, you want to establish its accuracy or the reason for processing it. You would generally ask for this restriction when you are asking for other information.'
  ),
  createData(
    'Your right to data portability',
    'You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.'
  ),
  createData(
    'Object to the processing of your personal information',
    'If we are relying on your consent, or a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this basis.'
  ),
];

const ProPrivacyNotice = () => {
  const theme = useTheme();

  const {dark, contrastText} = theme.palette.primary;
  return (
    <>
      <PageHeader
        color={dark}
        name={'Privacy Notice'}
        icon={PrivacyHeaderIcon}
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{paddingTop: {md: 5, xs: 2, mb: 2}}}>
        <Typography variant="h4" gutterBottom>
          Summary
        </Typography>
        <Typography paragraph>
          Compass was created and is operated by ARC Scotland. ARC Scotland set up the Scottish
          Transition Forum and has been working with the Scottish Government, Local Authorities and
          other organisations to improve the experience of children and young adults (14 to 25
          years) with additional support needs as they make the transition to young adult life.
        </Typography>
        <Typography paragraph>
          Compass is part of this work and that project had the following aims:
        </Typography>
        <List>
          <PrivacyListItem text="To provide parents or guardians of a young person with additional support needs with relevant information at each transition stage reached;" />
          <PrivacyListItem text="To share the insights provided by parents and guardians to help improve the transition experience; and" />
          <PrivacyListItem text="To provide relevant information to professionals working in this area and to gather their feedback." />
        </List>
        <Typography paragraph>
          To do that we ask you to sign up to use Compass and then to answer various questions about
          yourself and your profession; about your involvement in the transition journey; and to
          evaluate that journey. This will allow us to provide you with information that is relevant
          to you.
        </Typography>
        <Typography paragraph>
          We will share a limited amount of profile information, transition information and
          evaluation information with local authorities. Any information shared will be aggregated
          and anonymised which means it will be pooled together in a way that means it cannot be
          linked back to you.
        </Typography>
        <Typography paragraph>
          We will also share information with the Scottish Government. We are working in partnership
          with them to help improve the transition experience. They will use the information to
          evaluate the project and this data is aggregated and anonymised.
        </Typography>
        <Typography paragraph>
          We will publish reports from time to time which will contain information obtained through
          Compass but which will never identify any individual. These reports will be publicly
          available with the same aim of improving the transition process.
        </Typography>
        <Typography paragraph>
          This privacy notice is here to help you understand what we are doing with your personal
          data; how we are keeping it safe and to tell you about the rights that you have in
          relation to your personal data. We want you to feel comfortable that the personal
          information you are giving to us will be looked after.
        </Typography>
        <Divider sx={{m: 2}} />
        <Typography variant="h4" gutterBottom>
          Our contact details
        </Typography>
        <Typography gutterBottom sx={{ml: 2}}>
          <b>Name</b>: Association for Real Change
        </Typography>
        <Typography gutterBottom sx={{ml: 2}}>
          <b>Address</b>: ARC House, Marden Street, Chesterfield, Derbyshire S40 1JY
        </Typography>
        <Typography gutterBottom sx={{ml: 2}}>
          <b>Email</b>:{' '}
          <Link href="mailto:compass@arcuk.org.uk" target="_blank" rel="noreferrer">
            compass@arcuk.org.uk
          </Link>
        </Typography>
        <Divider sx={{m: 2}} />
        <Typography variant="h4" gutterBottom>
          The type of personal information we collect and what we do with it
        </Typography>
        <Typography paragraph>
          We collect and use the following information through Compass:
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Type of information</b>
                </TableCell>
                <TableCell align="center">
                  <b>Purpose</b>
                </TableCell>
                <TableCell align="center">
                  <b>Shared with another controller?</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {typesRows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    <b>{row.name}</b>
                  </TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider sx={{m: 2}} />
        <Typography variant="h4" gutterBottom>
          How we get the personal information and why we have it
        </Typography>
        <Typography paragraph>
          The only source of information about you is from you. We do not gather any more
          information about you from other places. It is only the information that you give to us
          that we use.
        </Typography>
        <Typography paragraph>
          You may have heard about something called Cookies. These allow websites and Apps to
          function better but can also be used to gather a lot of information about how you behave
          online. We only use Cookies which are absolutely necessary to allow Compass to function
          and recognise you.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Lawful Basis
        </Typography>
        <Typography paragraph>
          Under the General Data Protection Regulation (GDPR), we need to have a lawful basis to use
          your information. We have set these out below.
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Lawful Basis for collecting the data</b>
                </TableCell>
                <TableCell align="center">
                  <b>Purpose</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lawfulBasisRows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    <b>{row.name}</b>
                  </TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider sx={{m: 2}} />
        <Typography variant="h4" gutterBottom>
          If you do not provide us with personal data
        </Typography>
        <Typography paragraph>
          We hope that the information in this notice reassures you about why we are asking for your
          personal data, but if you do not wish to provide the information we ask for, Compass will
          not work properly and we may be unable to provide you with relevant information.
        </Typography>
        <Divider sx={{m: 2}} />
        <Typography variant="h4" gutterBottom>
          How we store your personal information
        </Typography>
        <Typography paragraph>
          We work closely with a technology company called Wheelhouse who helped us to develop
          Compass and who keep it working properly. They also store the information gathered in
          Compass on their database on our behalf.
        </Typography>
        <Typography paragraph>
          Wheelhouse are legally called a processor which means that they provide us with the
          services we asked them to, under a legal contract we have with them. The terms of this
          contract mean that they cannot do anything with your information unless we have instructed
          them to do that. They will not share your personal information with any organisation apart
          from us or as instructed by us. They will hold it securely and only for as long as we
          instruct them to.
        </Typography>
        <Typography paragraph>
          Wheelhouse work with organisations like ARC to co-design websites and mobile applications
          with the aim of creating technology that is designed to work for everyone, including
          disabled and neurodiverse people.
        </Typography>
        <Typography paragraph>
          Wheelhouse uses a database run by Amazon Web Services which is hosted in the UK and other
          services to send your email and SMS reminders if requested. Their processes have been
          secured using cyber security expert advice.
        </Typography>
        <Typography paragraph>
          The information gathered by Compass is encrypted when it is transferred between your
          device and our database. It is also encrypted as it is stored. Before information is
          shared with local authorities and the Scottish Government, it is separated from your
          personal details, combined with other information and only this aggregated and anonymised
          information is shared.
        </Typography>
        <Divider sx={{m: 2}} />
        <Typography variant="h4" gutterBottom>
          How long we hold onto your data
        </Typography>
        <Typography paragraph>
          We keep your account open until you tell us to close it. Or if it is five years since you
          have used your account, we will contact you and unless you tell us otherwise, we will
          close your account. We will contact you using the email address or phone number you
          provided to us. Once your account is closed, your account information will be securely
          deleted from our database.
        </Typography>
        <Typography paragraph>
          We will retain the other information that you provided to us, but this will be anonymous.
          This will continue to assist us to improve the transition journey for young people in
          Scotland.
        </Typography>
        <Divider sx={{m: 2}} />
        <Typography variant="h4" gutterBottom>
          Your data protection rights
        </Typography>
        <Typography paragraph>Under data protection law, you have rights including:</Typography>
        <TableContainer component={Paper} sx={{mb: 2}}>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Your Rights</b>
                </TableCell>
                <TableCell align="center">
                  <b>What they mean in practice</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rightsRows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    <b>{row.name}</b>
                  </TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography paragraph>
          You are not required to pay anything for exercising your rights. If you make a request, we
          have one month to respond to you.
        </Typography>
        <Divider sx={{m: 2}} />
        <Typography variant="h4" gutterBottom>
          Queries or concerns
        </Typography>
        <Typography paragraph>
          Please contact us using the contact details above to make a request or to raise any
          concerns you have about how the personal data gathered through Compass is being used. You
          can also contact the Information Commissioner’s Office if you have concerns and their
          contact details can be found here:{' '}
          <Link href="https://ico.org.uk/global/contact-us/" target="_blank" rel="noreferrer">
            ico.org.uk/global/contact-us/
          </Link>
          .
        </Typography>
      </Container>
    </>
  );
};
export default ProPrivacyNotice;
