import React from 'react';
import {Box, Typography} from '@mui/material';

import {PASSWORD_REQUIREMENTS} from './constants';

const PasswordRules = () => {
  return (
    <Box sx={{mb: 1}}>
      <Typography>Your password must:</Typography>
      {PASSWORD_REQUIREMENTS.map((requirement) => (
        <Typography variant="body2" key={requirement} gutterBottom>
          - {requirement}
        </Typography>
      ))}
    </Box>
  );
};

export default PasswordRules;
