import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Breadcrumbs, Link, Container, Typography} from '@mui/material';

import useCategoriesWithActions from '../Queries/useCategoriesWithActions';
import ProCategories from './ProCategories';
import {categoryIcons} from '../Util/constants';

const ProParentCategories = () => {
  const {data: categories, isLoading, error} = useCategoriesWithActions();
  const categoriesWithIcons =
    categories?.map((category) => ({
      ...category,
      icon: categoryIcons[category.name],
    })) ?? [];

  return (
    <Container sx={{mt: 2}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Typography color="text.primary">Parents</Typography>
      </Breadcrumbs>
      <Typography variant="h2" textAlign="center" gutterBottom>
        Parents
      </Typography>
      <ProCategories categories={categoriesWithIcons} isLoading={isLoading} error={error} />
    </Container>
  );
};

export default ProParentCategories;
