import React, {useEffect, useState} from 'react';
import {Button, Container, Paper, Typography} from '@mui/material';

import {useAuth} from './AuthProvider';
import BackLink from '../Util/BackLink';
import {EMAIL_VALIDATION_REGEX_STRING} from '../Util/constants';

const ForgotPassword = () => {
  const {authState, requestPasswordReset} = useAuth();

  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [buttonDisabled, setButtonDisabled] = useState(undefined);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    const response = await requestPasswordReset(email);
    if (response && response.status === 200) {
      setMessage(response.data.message);
      setEmailSubmitted(true);
    } else {
      setButtonDisabled(false);
      setEmailSubmitted(false);
    }
  };

  useEffect(() => {
    setButtonDisabled(email === undefined || email === '');
  }, [email]);

  useEffect(() => {
    if (authState.isAuthenticated) {
      setEmail(authState.user.email);
    }
  }, [authState]);

  return (
    <Container maxWidth="sm" sx={{py: 4}}>
      <BackLink to={authState.isAuthenticated ? '/my-account/edit/password' : '/login'} />
      <Paper sx={{mt: 4, p: 4, borderRadius: 5}}>
        <form onSubmit={handleFormSubmit}>
          <Typography variant="h2" component="h1" gutterBottom>
            Forgot your password?
          </Typography>
          {emailSubmitted ? (
            <Typography paragraph>{message}</Typography>
          ) : (
            <>
              <Typography paragraph>
                Please enter your email and we will send you a link to reset your password
              </Typography>
              <input
                autoFocus
                type="email"
                pattern={EMAIL_VALIDATION_REGEX_STRING}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Email"
                value={email}
              />
              <div className="form-button">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={buttonDisabled || emailSubmitted}>
                  Submit
                </Button>
              </div>
            </>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
