import React from 'react';

import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Container, Typography, Link, Button} from '@mui/material';

import useEvaluationHistory from '../../Queries/useEvaluationHistory';
import useEvaluationNew from '../../Queries/useEvaluationNew';
import PageHeader from '../../Util/PageHeader';
import feedbackIcon from '../../img/EvaluationLight.svg';

export default function ProEvaluationAbout() {
  const navigate = useNavigate();
  const {data: evaluations, isLoading, isError} = useEvaluationHistory();
  const newEvaluation = useEvaluationNew();

  const theme = useTheme();
  const {feedbackColor} = theme.palette;

  if (isLoading) {
    return <div>Loading</div>;
  }
  if (isError) {
    return <div>Error</div>;
  }

  const activeEvaluation = evaluations.find((evaluation) => evaluation.completed_at === null);

  return (
    <>
      <PageHeader color={feedbackColor} name="Feedback" icon={feedbackIcon} backTo="/" />
      <Container maxWidth="md" sx={{paddingTop: {md: 5, xs: 2}}}>
        <Typography variant="h2" gutterBottom>
          Tell us about your experience with transitions
        </Typography>
        <Typography paragraph>
          Please answer as honestly as you can and include both good and bad experiences. No one
          will be able to identify you from your response, it will be added to other people's
          answers and shared with your local authority or healthboard area.
        </Typography>
        <Typography paragraph>
          This will help us to evaluate{' '}
          <Link href="https://www.pn2p.scot/background/" target="_blank" rel="noreferrer">
            Principles into Practice
          </Link>{' '}
          and improve transitions for everyone.
        </Typography>
        <Typography paragraph>
          Please read our{' '}
          <Link component={RouterLink} to="/privacy" target="_blank" rel="noreferrer">
            Privacy Notice
          </Link>{' '}
          to find out how your data is used.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (!activeEvaluation) {
              newEvaluation.mutate(null, {onSuccess: (data) => navigate(`/feedback/${data.id}`)});
            } else {
              navigate(`/feedback/${activeEvaluation.id}`);
            }
          }}>
          {activeEvaluation ? 'Continue' : 'Get started'}
        </Button>
        <Button component={RouterLink} sx={{ml: 2}} to="/feedback/history">
          What you said before
        </Button>
      </Container>
    </>
  );
}
