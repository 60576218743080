import React from 'react';

import {Slider, Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ResponsiveSlider = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const responsiveSliderSx = isMobile
    ? {
        height: '150px',
        mt: 2,
      }
    : {
        width: '90%',
        '& .MuiSlider-markLabel': {maxWidth: '100px', whiteSpace: 'normal', textAlign: 'center'},
      };

  const responsiveBoxSx = isMobile
    ? {justifyContent: 'flex-start', alignItems: 'flext-start'}
    : {justifyContent: 'center', alignItems: 'center'};

  const responsiveSliderProps = {
    sx: {...props.sx, ...responsiveSliderSx},
    // use props.orientation if it exists, otherwise use isMobile to determine orientation
    orientation: props.orientation ? props.orientation : isMobile ? 'vertical' : 'horizontal',
  };

  return (
    <Box sx={{display: 'flex', my: 2, ...responsiveBoxSx}}>
      <Slider {...props} {...responsiveSliderProps} />
    </Box>
  );
};

export default ResponsiveSlider;
