import React from 'react';
import {useTheme} from '@mui/material/styles';

import PageHeader from '../Util/PageHeader';
import AccountHeaderIcon from '../img/account-header.svg';
import EditMyAccountDetails from './EditMyAccountDetails';

const EditAccountScreen = ({profileDetails}) => {
  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;

  return (
    <>
      <PageHeader
        color={dark}
        name={'My Account'}
        icon={AccountHeaderIcon}
        textColor={contrastText}
        backTo="/my-account"
      />
      <EditMyAccountDetails profileDetails={profileDetails} />
    </>
  );
};

export default EditAccountScreen;
