import React from 'react';
import {Link} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Button, Container} from '@mui/material';

const BackLink = ({color, to, backText = 'Back'}) => (
  <Container maxWidth="md">
    <Button
      component={Link}
      to={to}
      sx={{color, pl: 0}}
      startIcon={<ArrowBackIcon color={color} />}>
      {backText}
    </Button>
  </Container>
);

export default BackLink;
