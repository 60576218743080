import React, {useState} from 'react';
import {CircularProgress, Button, Container} from '@mui/material';

import AccountUpdatedDialog from '../Account/AccountUpdatedDialog';

const ProAccountFieldEdit = ({
  handleSubmit,
  onSubmit,
  children,
  buttonDisabled,
  dialogMessage = '',
}) => {
  const [shouldShowUpdateDialog, setShouldShowUpdateDialog] = useState(false);
  const [updateWasSuccessful, setUpdateWasSuccessful] = useState(undefined);
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        setIsUpdating(true);
        const response = await onSubmit(data);
        if (response && response.status === 200) {
          setUpdateWasSuccessful(true);
        } else {
          setUpdateWasSuccessful(false);
        }
        setShouldShowUpdateDialog(true);
        setIsUpdating(false);
      })}>
      <AccountUpdatedDialog
        isVisible={shouldShowUpdateDialog}
        closeDialog={() => setShouldShowUpdateDialog(false)}
        updateWasSuccessful={updateWasSuccessful}
        dialogMessage={dialogMessage}
      />
      <Container maxWidth="md" sx={{py: 6}}>
        {isUpdating ? (
          <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress size={100} color="secondary" />
          </div>
        ) : (
          children
        )}
        <Button
          variant="contained"
          color="secondary"
          style={{float: 'right'}}
          disabled={buttonDisabled}
          type="submit">
          Save
        </Button>
      </Container>
    </form>
  );
};

export default ProAccountFieldEdit;
