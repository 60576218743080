import React from 'react';
import {Box, ListItemButton, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

import AccountItem from './AccountItem';

const MyAccountDetails = ({user}) => {
  const navigate = useNavigate();
  const {name, email, email_notifications, phone_number, sms_notifications} = user;
  return (
    <>
      <AccountItem heading="Name:" onClick={() => navigate('edit/name')}>
        <Typography variant="body2">{name}</Typography>
      </AccountItem>
      <AccountItem heading="Email:">
        <Typography variant="body2">{email}</Typography>
      </AccountItem>
      <AccountItem heading="Phone number:" onClick={() => navigate('edit/phone-number')}>
        <Typography variant="body2">{phone_number}</Typography>
      </AccountItem>
      <AccountItem heading="Notifications:" onClick={() => navigate('edit/notifications')}>
        <>
          <Typography variant="body2">Email: {email_notifications ? 'On' : 'Off'}</Typography>
          <Typography variant="body2">SMS: {sms_notifications ? 'On' : 'Off'}</Typography>
        </>
      </AccountItem>
      <AccountItem heading="Password:" onClick={() => navigate('edit/password')}>
        <Typography variant="body2">********</Typography>
      </AccountItem>
      <ListItemButton onClick={() => navigate('delete')} sx={{p: 2}} divider>
        <Box sx={{flex: 1}}>
          <Typography color="secondary">Delete my account</Typography>
        </Box>
        <DeleteIcon color="secondary" />
      </ListItemButton>
    </>
  );
};

export default MyAccountDetails;
