import React, {useEffect, useReducer, useContext, createContext} from 'react';
import {SentryRoutes} from '../Sentry';
import {Route, Navigate} from 'react-router-dom';

import {useAuth} from '../Auth/AuthProvider';
import {useYoungPerson} from '../Parent/YoungPersonProvider';

export const OnboardingContext = createContext();

const LOCAL_AUTHORITIES_URL = '/api/local-authorities';
const ADDITIONAL_SUPPORT_NEEDS_URL = '/api/additional-support-needs';
const SCHOOL_ATTRIBUTE_VALUES = '/api/school-attribute-values';
const YOUNG_PERSON_ADDITIONAL_SUPPORT_NEED_CATEGORIES =
  '/api/young-person-additional-support-need-categories';

const SUBMIT_ONBOARDING = '/api/onboarding/submit-onboarding';
const SCHOOL_YEARS = '/api/onboarding/school-years';
const TRANSITION_STAGE_DATES_URL = '/api/onboarding/transition-stage-dates';
const CURRENT_TRANSITION_STAGE_URL = '/api/onboarding/current-transition-stage';
const EXPECTED_LEAVING_DATES = '/api/onboarding/expected-leaving-dates';
const STAGES_URL = '/api/stages';

const initialState = {
  additionalSupportNeeds: undefined,
  schoolTypes: undefined,
  schoolYears: undefined,
  schoolTerms: undefined,
  localAuthorities: undefined,
  stages: undefined,
  youngPersonAdditionalSupportNeedCategories: undefined,
};

const onboardingReducer = (onboardingState, action) => {
  switch (action.type) {
    case 'SET_LOCAL_AUTHORITIES':
      return Object.assign({}, onboardingState, {
        localAuthorities: action.payload,
      });
    case 'SET_ADDITIONAL_SUPPORT_NEEDS':
      return Object.assign({}, onboardingState, {
        additionalSupportNeeds: action.payload,
      });
    case 'SET_SCHOOL_ATTRIBUTE_VALUES':
      return Object.assign({}, onboardingState, {
        schoolTypes: action.payload.types,
        schoolYears: action.payload.years,
        schoolTerms: action.payload.terms,
      });
    case 'SET_STAGES':
      return Object.assign({}, onboardingState, {
        stages: action.payload,
      });
    case 'SET_YOUNG_PERSON_ADDITIONAL_SUPPORT_NEED_CATEGORIES':
      return Object.assign({}, onboardingState, {
        youngPersonAdditionalSupportNeedCategories: action.payload,
      });
  }
};

function OnboardingProvider({children, Onboarding}) {
  const [onboardingState, onboardingDispatch] = useReducer(onboardingReducer, initialState);
  const {fetchUser, authState, axios, isParent, isYoungPerson, isProfessional} = useAuth();
  // youngPersonContext is only defined in the Parent app
  // (we use this to show the Onboarding flow again when adding another young person)
  const youngPersonContext = useYoungPerson();

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        fetchAdditionalSupportNeeds(),
        fetchSchoolAttributeValues(),
        fetchLocalAuthorities(),
      ]);
    };
    init();
  }, []);

  useEffect(() => {
    if (isParent || isProfessional) {
      fetchStages();
    } else if (isYoungPerson) {
      fetchAdditionalSupportNeedCategories();
    }
  }, [isParent, isYoungPerson, isProfessional]);

  const fetchLocalAuthorities = async () => {
    const localAuthoritiesResponse = await axios.get(LOCAL_AUTHORITIES_URL);
    onboardingDispatch({type: 'SET_LOCAL_AUTHORITIES', payload: localAuthoritiesResponse.data});
  };

  const fetchAdditionalSupportNeeds = async () => {
    const additionalSupportNeedsResponse = await axios.get(ADDITIONAL_SUPPORT_NEEDS_URL);
    onboardingDispatch({
      type: 'SET_ADDITIONAL_SUPPORT_NEEDS',
      payload: additionalSupportNeedsResponse.data,
    });
  };
  const fetchAdditionalSupportNeedCategories = async () => {
    const additionalSupportNeedCategoriesResponse = await axios.get(
      YOUNG_PERSON_ADDITIONAL_SUPPORT_NEED_CATEGORIES
    );
    onboardingDispatch({
      type: 'SET_YOUNG_PERSON_ADDITIONAL_SUPPORT_NEED_CATEGORIES',
      payload: additionalSupportNeedCategoriesResponse.data,
    });
  };

  const fetchSchoolAttributeValues = async () => {
    const schoolAttributeValuesResponse = await axios.get(SCHOOL_ATTRIBUTE_VALUES);
    onboardingDispatch({
      type: 'SET_SCHOOL_ATTRIBUTE_VALUES',
      payload: schoolAttributeValuesResponse.data,
    });
  };

  const fetchStages = async () => {
    const stagesResponse = await axios.get(STAGES_URL);
    onboardingDispatch({type: 'SET_STAGES', payload: stagesResponse.data});
  };

  const submitProOnboardingData = async (data) => {
    console.log('DATA', data.region_type);
    const newData = {
      ...data,
      scotland_national: data.region_type === 'Scotland (National)',
    };
    const submitOnboardingResponse = await axios.post(SUBMIT_ONBOARDING, {
      data: newData,
    });
    await fetchUser();
    return submitOnboardingResponse.data;
  };

  const submitYpOnboardingData = async (
    birthMonth,
    birthYear,
    transitionYear,
    transitionMonth,
    transitionTypeID,
    localAuthority
  ) => {
    const submitOnboardingResponse = await axios.post(SUBMIT_ONBOARDING, {
      birth_month: birthMonth,
      birth_year: birthYear,
      leaving_year: transitionYear,
      leaving_month: transitionMonth,
      young_person_transition_type_id: transitionTypeID,
      local_authority_id: onboardingState.localAuthorities.find(
        (auth) => auth.name === localAuthority
      )?.id,
    });
    await fetchUser();
    return submitOnboardingResponse.data;
  };

  const calculateSchoolYearFormChoices = async (month, year) => {
    // backend expects month to be 2 digits (Y-mm-dd)
    const formattedMonth = month.padStart(2, '0');
    const dob = `${year}-${formattedMonth}-01`;
    const calculateSchoolYearFormChoicesResponse = await axios.put(SCHOOL_YEARS, {
      dob,
    });
    return calculateSchoolYearFormChoicesResponse.data;
  };

  const calculateTransitionStageDates = async (expectedLeavingTerm, expectedLeavingYear) => {
    const expectedLeavingMonth = expectedLeavingTerm === 'Summer' ? 6 : 12;
    const leavingDate = `${expectedLeavingYear}-${expectedLeavingMonth}-01`;
    const calculateTransitionStageDatesResponse = await axios.get(TRANSITION_STAGE_DATES_URL, {
      params: {
        leavingDate,
      },
    });
    return calculateTransitionStageDatesResponse.data;
  };

  const calculateCurrentTransitionStage = async (expectedLeavingTerm, expectedLeavingYear) => {
    const expectedLeavingMonth = expectedLeavingTerm === 'Summer' ? 6 : 12;
    const leavingDate = `${expectedLeavingYear}-${expectedLeavingMonth}-01`;
    const calculateCurrentTransitionStageResponse = await axios.get(CURRENT_TRANSITION_STAGE_URL, {
      params: {
        leavingDate,
      },
    });
    return calculateCurrentTransitionStageResponse.data;
  };

  const calculatePotentialExpectedLeavingDates = async (
    monthOfBirth,
    yearOfBirth,
    currentSchoolYear,
    hidePrevious = false,
    hideFuture = false
  ) => {
    const calculatePotentialExpectedLeavingDatesResponse = await axios.put(EXPECTED_LEAVING_DATES, {
      monthOfBirth,
      yearOfBirth,
      currentSchoolYear,
      hidePrevious,
      hideFuture,
    });
    return calculatePotentialExpectedLeavingDatesResponse.data;
  };

  const value = {
    onboardingState,
    onboardingDispatch,
    submitYpOnboardingData,
    submitProOnboardingData,
    calculateSchoolYearFormChoices,
    calculateTransitionStageDates,
    calculateCurrentTransitionStage,
    calculatePotentialExpectedLeavingDates,
  };

  return (
    <OnboardingContext.Provider value={value}>
      {authState.user.hasOnboarded === undefined ? (
        'Loading...'
      ) : !authState.user.hasOnboarded || youngPersonContext?.isAddingAdditionalYoungPerson ? (
        <SentryRoutes>
          <Route path="/register/onboarding/*" element={<Onboarding />} />
          <Route path="*" element={<Navigate to="/register/onboarding" replace />} />
        </SentryRoutes>
      ) : (
        children
      )}
    </OnboardingContext.Provider>
  );
}

const useOnboarding = () => useContext(OnboardingContext);

export {OnboardingProvider, useOnboarding};
