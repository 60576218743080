import React from 'react';
import {Typography, Card, CardActions, CardContent, CardHeader, Button} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useNavigate} from 'react-router-dom';

import LoadingCard from './LoadingCard';

const ProCategories = ({categories, isLoading, error}) => {
  const navigate = useNavigate();
  return (
    <>
      <Grid container alignItems="stretch" spacing={2}>
        {isLoading ? (
          [...Array(7)].map((_, index) => (
            <Grid xs={12} md={4} key={index}>
              <LoadingCard />
            </Grid>
          ))
        ) : error ? (
          <div>Error: Unable to fetch transition categories. Please try again later.</div>
        ) : (
          <>
            {categories?.map((category) => (
              <Grid xs={12} md={4} key={category.id}>
                <Card sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                  <CardHeader
                    avatar={<img src={category.icon} width={42} height={42} alt="" />}
                    title={category.name}
                  />
                  <CardContent sx={{flex: 1}}>
                    <Typography variant="body2">{category.description}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      endIcon={<ArrowForwardIcon />}
                      onClick={() => navigate(`${category.id}`)}
                      data-testid={category.name}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default ProCategories;
