import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Button,
  Container,
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  CardActionArea,
  Typography,
  List,
  ListItem,
  Link,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {useTheme} from '@mui/material/styles';

import {sectionIcons} from '../Util/constants';
import {useAuth} from '../Auth/AuthProvider';
import {legalMattersIcon} from '../Util/constants';

import HomeScreenTour from '../Util/HomeScreenTour';
import {useRedirectAfterAuth} from '../Util/StoreRedirectUrlForAuth';

const ProHomeScreenCard = ({title, description, image, backgroundColor, onClick, id}) => {
  return (
    <Grid id={id} item xs={12} md={6} lg={4}>
      <Card sx={{borderRadius: 5, height: '100%'}}>
        <CardActionArea
          onClick={onClick}
          sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
          <CardMedia
            component="img"
            height={140}
            image={image}
            sx={{objectFit: 'contain', backgroundColor, padding: 1}}
          />
          <CardContent sx={{flex: 1}}>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const TOUR_JOYRIDE_STEPS = [
  {
    target: 'body',
    placement: 'center',
    disableBeacon: true,
    title: 'Welcome to Compass!',
    content:
      'Compass helps you understand your key duties as a professional, and view the information that is available to parents and young people on their versions of Compass. This short guided tour will help you know how to use Compass.',
  },
  {
    target: '#home-button',
    disableBeacon: true,
    title: 'Home button',
    content: 'Click here to return to the home screen.',
  },
  {
    target: '#glossary-nav-button',
    title: 'Transitions A-Z',
    fixed: true,
    content: (
      <>
        <Typography paragraph>
          The Transitions A-Z lets you look up commonly used terms to help you understand the jargon
          often used during transitions.
        </Typography>
        <Typography paragraph>
          Each item has Helpful Links so you can learn more about topics when you need to.
        </Typography>
      </>
    ),
  },
  {
    target: '#menu-nav-button',
    title: 'Main menu',
    content: (
      <>
        <Typography paragraph>
          This is the main menu. You can use it to navigate to different parts of Compass and toggle
          between dark/light display mode.
        </Typography>
      </>
    ),
  },
  {
    target: '#professionals-card',
    title: 'Professionals resources',
    content: (
      <>
        <Typography paragraph>
          This section shows you the key duties of professionals at each stage of the transition
          process.
        </Typography>
        <Typography paragraph>Click on the card to see the key duties for each stage.</Typography>
      </>
    ),
  },
  {
    target: '#parents-card',
    title: 'Parents resources',
    content: (
      <>
        <Typography paragraph>This section shows you the Compass resources for parents.</Typography>
        <Typography paragraph>
          The content in this section is the same content that parents see on their version of
          Compass.
        </Typography>
      </>
    ),
  },
  {
    target: '#young-people-card',
    title: 'Young people resources',
    content: (
      <>
        <Typography paragraph>
          This section shows you the Compass resources for young people.
        </Typography>
        <Typography paragraph>
          The content in this section is the same content that young people see on their version of
          Compass.
        </Typography>
      </>
    ),
  },
  {
    target: '#timeline-card',
    title: 'Timeline',
    content: (
      <>
        <Typography paragraph>
          This section shows you the key things for parents to consider at each stage of the
          transition process.
        </Typography>
        <Typography paragraph>
          There is also a stage calculator to help you work out what stage a young person is at
          based on their circumstances.
        </Typography>
      </>
    ),
  },
  {
    target: '#feedback-card',
    title: 'Feedback',
    content: (
      <>
        <Typography paragraph>
          Here you can submit feedback on your experience of working with transitions. This helps to
          evaluate how well Principles into Practice and Compass are working.
        </Typography>
      </>
    ),
  },
  {
    target: '#resources-card',
    title: 'Helpful resources',
    content: (
      <>
        <Typography paragraph>This section contains links to other helpful resources.</Typography>
      </>
    ),
  },
];

const ProHome = () => {
  const {authState, updateUser} = useAuth();
  const navigate = useNavigate();

  // If there's a stored URL when we mount, navigate there
  const {handleAuthenticatedAppBoot} = useRedirectAfterAuth();
  useEffect(handleAuthenticatedAppBoot, []);

  const {
    transitionQuestionsIcon,
    transitionTopicsIcon,
    feedbackIcon,
    transitionTopicCategoryIcons,
  } = sectionIcons;

  const theme = useTheme();
  const {feedbackColor} = theme.palette;

  const [showTour, setShowTour] = useState(authState.user.should_show_intro_tour);
  const onCompleteTour = async () => {
    await updateUser({should_show_intro_tour: false});
  };

  return (
    <>
      <HomeScreenTour
        run={showTour}
        setRun={setShowTour}
        steps={TOUR_JOYRIDE_STEPS}
        onComplete={onCompleteTour}
        hideProgress
      />
      <Container maxWidth="lg" sx={{paddingTop: {md: 5, xs: 2}}}>
        <Box sx={{display: 'flex', mb: 2, flexDirection: {xs: 'column', sm: 'row'}}}>
          <Box sx={{flex: 1, display: {xs: 'none', sm: 'block'}}} />
          <Box sx={{flex: 2}}>
            <Typography textAlign="center" variant="h3">
              Hello {authState?.user?.name}
            </Typography>
            <Typography textAlign="center">Welcome to Compass for Professionals</Typography>
          </Box>
          <Box sx={{flex: 1, textAlign: {xs: 'center', sm: 'end'}}}>
            <Button
              id="help-button"
              variant="contained"
              color="secondary"
              onClick={() => setShowTour(true)}>
              Help
            </Button>
          </Box>
        </Box>
        <Grid container spacing={4}>
          <ProHomeScreenCard
            id="professionals-card"
            title="Professionals"
            description="See the key duties of professionals at each stage."
            link="/key-duties"
            image={legalMattersIcon}
            backgroundColor="#d0b564"
            onClick={() => navigate('/key-duties')}
          />
          <ProHomeScreenCard
            id="parents-card"
            title="Parents"
            description="See the Compass resources for parents."
            link="/parent-categories"
            image={transitionTopicsIcon}
            backgroundColor="secondary.pale"
            onClick={() => navigate('/parent-categories')}
          />
          <ProHomeScreenCard
            id="young-people-card"
            title="Young People"
            description="See the Compass resources for young people."
            link="/young-person-categories"
            image={transitionTopicCategoryIcons}
            backgroundColor="primary.light"
            onClick={() => navigate('/young-person-categories')}
          />

          <ProHomeScreenCard
            id="timeline-card"
            title="Transition Timeline"
            description="View the transition timeline and key actions at each stage."
            link="/timeline"
            image={transitionQuestionsIcon}
            backgroundColor="primary.pale"
            onClick={() => navigate('/timeline')}
          />
          <ProHomeScreenCard
            id="feedback-card"
            title="Feedback"
            description="Provide feedback on the Compass for Professionals website."
            link="/feedback"
            image={feedbackIcon}
            backgroundColor={feedbackColor}
            onClick={() => navigate('/feedback')}
          />
          <Grid item xs={12} md={6} lg={4} id="resources-card">
            <Card sx={{borderRadius: 5, height: '100%'}}>
              <CardHeader
                title="Resources"
                titleTypographyProps={{variant: 'h3'}}
                sx={{
                  textAlign: 'center',
                  backgroundColor: '#4b7981',
                  color: 'white',
                }}
              />
              <CardContent>
                <List dense>
                  <ListItem>
                    <Link href="https://www.pn2p.scot/background/" target="_blank" rel="noreferrer">
                      Principles into Practice
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href="https://scottishtransitions.org.uk/7-principles-of-good-transitions/"
                      target="_blank"
                      rel="noreferrer">
                      Principles of Good Transition
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href="https://scottishtransitions.org.uk/training?mtm_campaign=Compass-Pro-App&mtm_kwd=stf-training&mtm_source=compass-pro&mtm_placement=pro-home-screen"
                      target="_blank"
                      rel="noreferrer">
                      Scottish Transition Forum Training
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href="https://arcscotland.org.uk/training/svqs-we-offer?mtm_campaign=Compass-Pro-App&mtm_kwd=svq-training&mtm_source=compass-pro&mtm_placement=pro-home-screen"
                      target="_blank"
                      rel="noreferrer">
                      ARC Scotland SVQ Certification
                    </Link>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ProHome;
