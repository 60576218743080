import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import {Button} from '@mui/material';

import {BANNER_MESSAGE} from '../Util/constants';
import {useAuth} from '../Auth/AuthProvider';

const AppBannerMessage = () => {
  const {authState} = useAuth();
  return BANNER_MESSAGE ? (
    <div>
      <Alert variant="filled" severity="info" sx={{borderRadius: 0, justifyContent: 'center'}}>
        {BANNER_MESSAGE}
        {BANNER_MESSAGE.toLowerCase().includes('feedback') && authState.isAuthenticated ? (
          <>
            {' '}
            <Button
              component={RouterLink}
              to="/feedback"
              size="small"
              sx={{m: 0, pv: 0, ml: 2}}
              variant="contained"
              color="secondary">
              Have your say!
            </Button>
          </>
        ) : null}
      </Alert>
    </div>
  ) : null;
};

export default AppBannerMessage;
