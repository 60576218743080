import React from 'react';
import {useParams, Link as RouterLink} from 'react-router-dom';
import {
  Breadcrumbs,
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Link,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@mui/material';

import useCategoriesWithActions from '../Queries/useCategoriesWithActions';
import GlossaryAnnotatedText from '../Util/GlossaryAnnotatedText';

const ProViewAction = () => {
  const {categoryId, actionId} = useParams();
  const {data: categories, isLoading, error} = useCategoriesWithActions();
  const category = categories?.find((category) => category.id === parseInt(categoryId));
  const action = category?.uniqueKeyActions.find((action) => action.id === parseInt(actionId));

  if (isLoading) {
    return <div>Loading</div>;
  }
  if (error) {
    return <div>Error</div>;
  }
  return (
    <Container sx={{mt: 2}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to="/parent-categories">
          Parents
        </Link>
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to={`/parent-categories/${categoryId}`}>
          {category.name}
        </Link>
        <Typography color="text.primary">{action.name}</Typography>
      </Breadcrumbs>
      <Box display="flex" justifyContent="center">
        <Card sx={{p: 4, maxWidth: '80%'}}>
          <CardHeader title={action.name} />
          <CardContent>
            <GlossaryAnnotatedText text={action.description} />
          </CardContent>
          <CardActions>
            <List>
              {action.key_action_links.map((link) => (
                <ListItem key={`action-${link.id}`}>
                  <Link href={link.url} target="_blank" rel="noreferrer">
                    {link.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};
export default ProViewAction;
