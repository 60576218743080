import {useSearchParams} from 'react-router-dom';
import {DIALOG_URL_PARAMS} from './constants';

const useDialogQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dialogQuery = searchParams.get('dialog') ?? '';

  const openDialog = (dialogName) => {
    setSearchParams((params) => {
      return {...params, dialog: dialogName};
    });
  };

  const closeDialog = () =>
    setSearchParams((params) => {
      params.delete(DIALOG_URL_PARAMS.KEY);
      params.delete(DIALOG_URL_PARAMS.GLOSSARY_TERM);
      return params;
    });

  return {dialogQuery, openDialog, closeDialog};
};

export default useDialogQuery;
