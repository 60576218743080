import React from 'react';
import {Box, Typography, Card, CardContent} from '@mui/material';

import IconContainer from './Pro/IconContainer';

const LandingPageFeature = ({iconColor, iconSrc, title, description}) => (
  <Card sx={{borderRadius: 5, my: 3}}>
    <CardContent>
      <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
        <IconContainer backgroundColor={iconColor}>
          <img src={iconSrc} height={43} alt="" />
        </IconContainer>
        <Typography variant="h3" component="h4">
          {title}
        </Typography>
      </Box>
      <Typography paragraph>{description}</Typography>
    </CardContent>
  </Card>
);
export default LandingPageFeature;
