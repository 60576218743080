import React, {useState} from 'react';
import {Dialog, Button, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

import {onCloseUnlessBackdropClick} from '../Util/helpers';
import {useAuth} from '../Auth/AuthProvider';
import PhoneNumberInputField from '../Util/PhoneNumberInputField';

const PhoneNumberDialog = ({isVisible, closeDialog}) => {
  const {updateUser} = useAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  return (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Phone Number</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          Compass can send you helpful reminders by text message, like when you have outstanding
          Things to Do.
        </Typography>
        <Typography paragraph>
          We won't share your number with anyone else or use it to contact you for any other
          purpose.
        </Typography>
        <PhoneNumberInputField value={phoneNumber} onChangeSetter={setPhoneNumber} />
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={async () => {
              await updateUser({should_prompt_for_phone_number: false});
              closeDialog();
            }}>
            Don't ask again
          </Button>
          <Button variant="outlined" color="secondary" onClick={closeDialog}>
            Not now
          </Button>
          <Button
            disabled={phoneNumber === ''}
            variant="contained"
            color="secondary"
            onClick={async () => {
              await updateUser({
                phone_number: phoneNumber,
                should_prompt_for_phone_number: false,
              });
              closeDialog();
            }}>
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default PhoneNumberDialog;
