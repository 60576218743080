import React from 'react';
import {Box} from '@mui/material';

const IconContainer = ({children, backgroundColor = 'transparent'}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 60,
      width: 60,
      borderRadius: 2,
      mr: 2,
      overflow: 'hidden',
      backgroundColor,
    }}>
    {children}
  </Box>
);

export default IconContainer;
