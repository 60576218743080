import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Box, ListItemButton, Typography} from '@mui/material';

const AccountItem = ({heading, onClick, children}) => {
  return (
    <ListItemButton onClick={onClick} sx={{p: 2}} divider>
      <Box sx={{flex: 1}}>
        <Typography variant="h5" gutterBottom>
          {heading}
        </Typography>
        {children}
      </Box>
      {onClick && <ChevronRightIcon />}
    </ListItemButton>
  );
};

export default AccountItem;
