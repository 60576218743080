import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Typography, Button, Link} from '@mui/material';

import {CONTACT_EMAIL_ADDRESS} from '../../Util/constants';

const ProEvaluationComplete = () => (
  <>
    <Typography variant="h2" gutterBottom>
      Thank you for your feedback
    </Typography>
    <Typography paragraph>
      Your answers will help improve the transitions process for people in your area and across
      Scotland.
    </Typography>
    <Typography paragraph>
      If you have more feedback or you've had technical problems with Compass, please email us at{' '}
      <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} target="_blank" rel="noreferrer">
        {CONTACT_EMAIL_ADDRESS}
      </Link>
      .
    </Typography>
    <Button component={RouterLink} variant="contained" color="secondary" to="/feedback/history">
      What you said before
    </Button>
  </>
);

export default ProEvaluationComplete;
