import React from 'react';
import Slide from '@mui/material/Slide';
import {MONTHS} from './constants';

export const calculateAge = (dob) => {
  const dateOfBirth = new Date(parseInt(dob.year), parseInt(dob.month), 1);
  const today = new Date();
  const differenceInMilliseconds = today - dateOfBirth.getTime();
  const differenceInYears = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365);
  return Math.floor(differenceInYears);
};

export const DialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const randomiseNumberInRange = (min, max) => {
  let roundedMin = Math.ceil(min);
  let roundedMax = Math.floor(max);
  return Math.floor(Math.random() * (roundedMax - roundedMin + 1)) + roundedMin;
};

export const range = (start, end) => {
  const a = [start];
  let b = start + 1;
  while (b < end) {
    a.push(b);
    b = b + 1;
  }
  return a;
};

// We reuse this logic in lots of Dialogs throughout Compass
// disableBackdropClick was deprecated, instead onClose can react to the reason arg
export const onCloseUnlessBackdropClick = (event, reason, onClose) => {
  if (reason !== 'backdropClick') {
    onClose(event, reason);
  }
};

export const formatDateAsTerm = (date) => `${MONTHS[date.getMonth() + 1]} ${date.getFullYear()}`;

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getYoungPersonDisplayName = (youngPerson) =>
  youngPerson.nickname ?? `${MONTHS[youngPerson.dob.month].slice(0, 3)} ${youngPerson.dob.year}`;

// https://www.regular-expressions.info/unicode.html#:~:text=belonging%20to%20the-,%E2%80%9Cletter%E2%80%9D%20category,-with%20%5Cp%7BL
export const isLetter = (char) => char !== undefined && RegExp(/^\p{L}/, 'u').test(char);
