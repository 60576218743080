import React from 'react';

import {Paper, Typography} from '@mui/material';

import {PARENT_FEEDBACK_RATING_SCALE} from '../Util/constants';
import ResponsiveSlider from '../Util/ResponsiveSlider';

const EvaluationQuestion = ({question, response, setResponse}) => {
  const getAriaValueText = (value) =>
    PARENT_FEEDBACK_RATING_SCALE.find((item) => item.value === value).label;

  return (
    <Paper variant="outlined" sx={{padding: 4, my: 2}}>
      <Typography variant="h6">{question.title}</Typography>
      <ResponsiveSlider
        getAriaValueText={getAriaValueText}
        marks={PARENT_FEEDBACK_RATING_SCALE}
        max={PARENT_FEEDBACK_RATING_SCALE.length}
        min={1}
        step={1}
        sx={{
          '& .MuiSlider-mark': {width: '8px', height: '8px', borderRadius: '50%'},
          '& .MuiSlider-markActive': {opacity: 1, backgroundColor: 'currentColor'},
        }}
        value={response?.rating ?? 3}
        onChange={(_, newRating) => {
          setResponse({...response, rating: newRating});
        }}
      />
    </Paper>
  );
};

export default EvaluationQuestion;
