import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useEvaluationHistory = () => {
  const {data, isLoading, error} = useQuery({
    queryKey: ['evaluation-history'],
    queryFn: async () => await axios.get('/api/evaluation/history').then((res) => res.data),
  });

  return {data, isLoading, error};
};

export default useEvaluationHistory;
