import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Container, Link, Typography} from '@mui/material';

import PageHeader from './Util/PageHeader';
import {CONTACT_EMAIL_ADDRESS} from './Util/constants';
import ContactUsHeaderIcon from './img/contact-us-header.svg';

const ContactUs = () => {
  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;
  return (
    <>
      <PageHeader
        color={dark}
        name={'Contact Us'}
        icon={ContactUsHeaderIcon}
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{py: 4}}>
        <Typography variant="h2" gutterBottom>
          Contact Details
        </Typography>
        <Typography paragraph>
          To tell us about any technical issues with Compass, please contact us at{' '}
          <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} target="_blank" rel="noreferrer">
            {CONTACT_EMAIL_ADDRESS}
          </Link>
        </Typography>
      </Container>
    </>
  );
};

export default ContactUs;
