import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useHealthBoardAreas = () => {
  const {data, isLoading, error} = useQuery({
    queryKey: ['healthBoardAreas'],
    queryFn: async () => await axios.get('/api/health-board-areas').then((res) => res.data),
    staleTime: Infinity,
  });

  return {data, isLoading, error};
};

export default useHealthBoardAreas;
