import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarPurple500RoundedIcon from '@mui/icons-material/StarPurple500Rounded';
import GlossaryAnnotatedText from '../Util/GlossaryAnnotatedText';
import {Typography} from '@mui/material';

export const TransitionTimelineStageSummaryDialog = ({
  open,
  handleClose,
  selectedStage,
  showGlossaryLinks = false,
}) => {
  const keyActionsSummaryItems = selectedStage?.key_actions_summary?.split('~') ?? [];
  return (
    selectedStage && (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{selectedStage.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign: 'left'}}>
            The key things to think about at this stage are:
          </DialogContentText>
          <List dense={true}>
            {keyActionsSummaryItems.map((keyAction, index) => (
              <ListItem key={`key-action-${index}`}>
                <ListItemIcon>
                  <StarPurple500RoundedIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {showGlossaryLinks ? <GlossaryAnnotatedText text={keyAction} /> : keyAction}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};
