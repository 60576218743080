import React, {useState} from 'react';
import {Route, Navigate, useNavigate, useParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Container, Button, TextField, Typography} from '@mui/material';

import {SentryRoutes} from '../../Sentry';
import useEvaluationSaveRatings from '../../Queries/useEvaluationSaveRatings';
import useEvaluationQuestions from '../../Queries/useEvaluationQuestions';
import useEvaluationSaveComments from '../../Queries/useEvaluationSaveComments';

import EvaluationQuestion from '../../Parent/EvaluationQuestion';
import ProEvaluationComplete from './ProEvaluationComplete';
import PageHeader from '../../Util/PageHeader';
import feedbackIcon from '../../img/EvaluationLight.svg';
import {Box} from '@mui/system';

export default function ProEvaluation() {
  const theme = useTheme();
  const {evaluationId} = useParams();
  const {feedbackColor} = theme.palette;

  const navigate = useNavigate();
  const saveEvaluationRatings = useEvaluationSaveRatings(evaluationId);
  const saveEvaluationComments = useEvaluationSaveComments();
  const [responses, setResponses] = useState([]);
  const [comments, setComments] = useState('');

  const onGetEvaluationQuestionsSuccess = (responseData) => {
    // create response object
    const allQuestionsCompleted = Object.values(responseData).every(
      (theme) => theme.themeQuestions.length === 0
    );
    // if response data is an empty object, then the evaluation is completed
    const evaluationComplete = Object.keys(responseData).length === 0;
    if (evaluationComplete) {
      navigate('done');
      // if all questions are complete, but the overall evaluation isn't complete, then navigate to comments
    } else if (allQuestionsCompleted) {
      navigate('comments');
    } else {
      let newResponses = {};
      Object.entries(responseData).map(([_, theme]) => {
        theme.themeQuestions.forEach((question) => {
          newResponses = {
            ...newResponses,
            [question.id]: {
              questionId: question.id,
              rating: 3,
            },
          };
        });
      });
      setResponses(newResponses);
    }
  };
  const {
    data: evaluationQuestionsByTheme,
    isLoading,
    isError,
  } = useEvaluationQuestions(evaluationId, {onSuccess: onGetEvaluationQuestionsSuccess});

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error</div>;
  }

  return (
    <>
      <PageHeader color={feedbackColor} name="Feedback" icon={feedbackIcon} backTo="/feedback" />
      <Container maxWidth="md" sx={{paddingY: {md: 5, xs: 2}}}>
        <SentryRoutes>
          <Route
            path="responses"
            element={
              <>
                {Object.entries(evaluationQuestionsByTheme).map(([_, theme]) =>
                  theme.themeQuestions.map((question) => (
                    <EvaluationQuestion
                      key={`question-${question.id}`}
                      question={question}
                      response={responses[question.id]}
                      setResponse={(newResponse) =>
                        setResponses({
                          ...responses,
                          [question.id]: newResponse,
                        })
                      }
                    />
                  ))
                )}
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => saveEvaluationRatings.mutate(responses)}>
                    Save
                  </Button>
                </Box>
              </>
            }
          />
          <Route
            path="comments"
            element={
              <>
                <Typography paragraph>
                  Is there anything else you'd like to share about your experience with transitions?
                  Your comments won't be shared directly with local authorities, but ARC may read
                  these comments and choose to share insights with them.
                </Typography>
                <Typography paragraph sx={{fontStyle: 'italic'}}>
                  Please do not include names or any other personal information in these comments.
                </Typography>
                <TextField
                  id="other-comments"
                  label="Comments (optional)"
                  multiline
                  rows={4}
                  value={comments}
                  onChange={(e) => setComments(e.target.value.slice(0, 500))}
                  variant="outlined"
                  sx={{width: '100%', mb: 2}}
                  helperText={`${comments.length}/500 characters`}
                />
                <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                  <Button
                    sx={{ml: 'auto'}}
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      saveEvaluationComments.mutate(comments, {onSuccess: () => navigate('done')})
                    }>
                    Finish
                  </Button>
                </Box>
              </>
            }
          />
          <Route path="done" element={<ProEvaluationComplete />} />
          <Route path="*" element={<Navigate to="responses" replace />} />
        </SentryRoutes>
      </Container>
    </>
  );
}
