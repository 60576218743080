import React from 'react';
import {Box, Card, CardActionArea, Chip, Typography} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PreviousEvaluationRow = ({title, onClick, stage}) => (
  <Card onClick={onClick} sx={{borderRadius: 5, mb: 2}}>
    <CardActionArea
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingX: 4,
        paddingY: 2,
      }}>
      {stage && (
        <Box>
          <Typography variant="caption" component="div">
            Stage
          </Typography>
          <Chip color="secondary" label={stage.index + 1} sx={{mt: 0.5}} />
        </Box>
      )}
      <Typography variant="body2" sx={{mx: 1}}>
        {title}
      </Typography>
      <ArrowForwardIcon />
    </CardActionArea>
  </Card>
);

export default PreviousEvaluationRow;
