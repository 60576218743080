import React from 'react';
import {useParams, useNavigate, Link as RouterLink} from 'react-router-dom';
import {Breadcrumbs, Link, Container, Typography, Box, Divider} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import useCategoriesWithActions from '../Queries/useCategoriesWithActions';
import {categoryIcons} from '../Util/constants';
import ProItemCard from './Components/ProItemCard';
import LoadingCard from './LoadingCard';
const ProParentViewCategory = () => {
  const {data: categories, isLoading, error} = useCategoriesWithActions();
  const {categoryId} = useParams();
  const navigate = useNavigate();

  const category = categories?.find((category) => category.id === parseInt(categoryId)) ?? {
    name: '\xa0', // non-breaking space
  };

  const keyActionsWithIcons =
    category?.uniqueKeyActions?.map((keyAction) => {
      return {
        ...keyAction,
        icon: categoryIcons[category.name],
      };
    }) ?? [];

  // create a new object keyed by the key_action.group.name
  const keyActionsByGroup = keyActionsWithIcons.reduce((acc, keyAction) => {
    const groupName = keyAction.group.name;
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(keyAction);
    return acc;
  }, {});

  return (
    <Container sx={{mt: 2}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to="/parent-categories">
          Parents
        </Link>
        <Typography color="text.primary">{category.name}</Typography>
      </Breadcrumbs>
      <Typography variant="h2" textAlign="center" gutterBottom>
        {category.name}
      </Typography>
      <Grid container alignItems="stretch" spacing={2}>
        {isLoading ? (
          [...Array(9)].map((_, index) => (
            <Grid xs={12} md={4} key={index}>
              <LoadingCard />
            </Grid>
          ))
        ) : error ? (
          <div>Error: Unable to fetch transition categories. Please try again later.</div>
        ) : (
          <>
            {Object.keys(keyActionsByGroup).map((groupName) => (
              <Grid xs={12} key={groupName}>
                <Box sx={{p: 4, mb: 2}}>
                  <Typography variant="h3" gutterBottom>
                    {groupName}
                  </Typography>
                  <Grid container alignItems="stretch" spacing={2}>
                    {keyActionsByGroup[groupName].map((keyAction) => (
                      <Grid xs={12} md={4} key={`key-action${keyAction.id}`}>
                        <ProItemCard
                          item={keyAction}
                          onClick={() => navigate(`item/${keyAction.id}`)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Divider />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default ProParentViewCategory;
