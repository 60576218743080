import React from 'react';
import Dialog from '@mui/material/Dialog';

import {onCloseUnlessBackdropClick} from '../Util/helpers';
import {Button, Typography} from '@mui/material';

const DeleteAccountDialog = ({isVisible, closeDialog, handleDeleteAccount}) => {
  return (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className="alert-dialog-container">
        <Typography variant="h3" gutterBottom>
          Delete Account
        </Typography>
        <Typography paragraph>Permanently delete your account?</Typography>
        <div className="dialog-buttons-container">
          <Button color="secondary" variant="outlined" onClick={closeDialog}>
            Keep
          </Button>
          <Button variant="contained" color="secondary" onClick={handleDeleteAccount}>
            Delete
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteAccountDialog;
