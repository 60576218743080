import {useMutation} from '@tanstack/react-query';
import axios from 'axios';

const useEvaluationNew = () => {
  return useMutation({
    mutationFn: async () => await axios.post('/api/evaluation').then((res) => res.data),
  });
};

export default useEvaluationNew;
