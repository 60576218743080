import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import {TextField} from '@mui/material';

const PhoneNumberInputField = ({value, onChangeSetter}) => {
  return (
    <FormControl fullWidth>
      <TextField
        autoFocus={true}
        id="outlined-adornment-phone-number"
        value={value}
        onChange={(event) => onChangeSetter(event.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start">+44</InputAdornment>,
        }}
        inputProps={{minLength: 10, maxLength: 10}}
        placeholder="7700900000"
      />
    </FormControl>
  );
};
export default PhoneNumberInputField;
