import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useGlossaryTerms = () => {
  const {data, isLoading, error} = useQuery({
    queryKey: ['glossary-terms'],
    queryFn: async () => await axios.get('/api/glossary/terms').then((res) => res.data),
    staleTime: Infinity,
  });

  return {data, isLoading, error};
};

export default useGlossaryTerms;
