import React from 'react';
import {
  Dialog,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import {onCloseUnlessBackdropClick} from '../Util/helpers';

const AccountUpdatedDialog = ({
  isVisible,
  closeDialog,
  updateWasSuccessful,
  isUpdating,
  dialogMessage,
}) => {
  const title = updateWasSuccessful ? 'Update successful' : 'Could not update';
  const subtitle = updateWasSuccessful
    ? 'Your account details were successfully updated.'
    : `There was a problem updating your account details. ${dialogMessage}`;
  return isUpdating ? (
    <div className="center-content">
      <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress size={100} color="secondary" />
      </div>
    </div>
  ) : (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography paragraph>{subtitle}</Typography>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={closeDialog} autoFocus>
            Dismiss
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AccountUpdatedDialog;
