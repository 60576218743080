import React, {useState} from 'react';
import {Box, Button, Paper, Typography} from '@mui/material';
import {Container} from '@mui/system';

import {useOnboarding} from '../Onboarding/OnboardingProvider';
import TransitionTimeline from '../Transition/TransitionTimeline';
import FindCurrentStageDialog from './FindCurrentStageDialog';

const ProTimeline = () => {
  const {onboardingState} = useOnboarding();
  const {stages} = onboardingState;
  const [showStageFinder, setShowStageFinder] = useState(false);
  const [stageDates, setStageDates] = useState();
  const [currentStage, setCurrentStage] = useState();

  return (
    <Container maxWidth="md" sx={{py: 6}}>
      <Box sx={{textAlign: 'center'}}>
        <Typography variant="h2" gutterBottom>
          Transition Timeline
        </Typography>
        <Typography paragraph>
          Compass divides the transition process into several stages, to help parents/carers keep
          track of the main actions as time goes on.
        </Typography>
        <Typography paragraph>
          Click on the stages below to see more information about the key things to think about at
          each stage.
        </Typography>
      </Box>
      <Container maxWidth="sm">
        <Paper sx={{p: 2, my: 4, textAlign: 'center'}}>
          <Typography paragraph>
            If you know some details about a young person, you can find their current stage by
            pressing the button below
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowStageFinder(true)}
            sx={{mb: 2}}>
            Find current stage
          </Button>
          {currentStage ? (
            <Typography paragraph>
              Your current stage is: <b>{currentStage.name}</b>
            </Typography>
          ) : (
            <Typography paragraph>
              This can help you give them more tailored advice for their situation.
            </Typography>
          )}
        </Paper>
      </Container>

      <FindCurrentStageDialog
        open={showStageFinder}
        handleClose={() => setShowStageFinder(false)}
        handleResult={(stageDates, currentStage) => {
          setStageDates(stageDates);
          setCurrentStage(currentStage);
          setShowStageFinder(false);
        }}
      />

      <TransitionTimeline
        stageDates={stageDates ?? stages}
        highlightedStage={currentStage}
        highlightText="Current"
      />
    </Container>
  );
};

export default ProTimeline;
