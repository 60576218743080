import React from 'react';
import {SentryRoutes} from '../../Sentry';
import {Route, Navigate} from 'react-router-dom';
import ProOnboardingAboutYou from './ProOnboardingAboutYou';

export default function App() {
  return (
    <SentryRoutes>
      <Route path="about-you" element={<ProOnboardingAboutYou />} />
      <Route path="*" element={<Navigate to="about-you" replace />} />
    </SentryRoutes>
  );
}
