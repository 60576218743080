import {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useAuth} from '../Auth/AuthProvider';

export const useRedirectAfterAuth = () => {
  const {redirectUrlForAuth, setRedirectUrlForAuth} = useAuth();
  const navigate = useNavigate();
  const handleAuthenticatedAppBoot = () => {
    if (redirectUrlForAuth) {
      navigate(redirectUrlForAuth);
      setRedirectUrlForAuth(undefined);
    }
  };
  return {handleAuthenticatedAppBoot};
};

// This component is rendered when the user is not authenticated,
// but tries to navigate to an authenticated page
const StoreRedirectUrlForAuth = () => {
  const {setRedirectUrlForAuth} = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  // Store it so we can redirect once we're authenticated.
  useEffect(() => {
    if (location && location.pathname !== '/login') {
      setRedirectUrlForAuth(location.pathname);
      navigate('/login');
    }
  }, [location]);
  return;
};

export default StoreRedirectUrlForAuth;
