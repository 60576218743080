import React from 'react';

import PasswordRules from './PasswordRules';
import {PASSWORD_REGEX} from './constants';

const PasswordInputField = ({
  id,
  value,
  label,
  placeholder,
  shouldError = false,
  autoFocus = false,
  showRules = true,
  autoComplete = 'off',
  onChangeSetter,
}) => {
  const passwordMeetsRegex = PASSWORD_REGEX.test(value);
  const passwordFails = value?.length > 0 && !passwordMeetsRegex;
  return (
    <>
      {showRules && <PasswordRules />}
      {label && <label htmlFor={id}>{label}</label>}
      <input
        autoComplete={autoComplete}
        value={value}
        onChange={(event) => onChangeSetter(event.target.value)}
        id={id}
        autoFocus={autoFocus}
        className={
          value ? (passwordFails || shouldError ? 'text-input-error' : 'text-input-success') : ''
        }
        type="password"
        minLength="8"
        required
        placeholder={placeholder}
      />
      {passwordFails && (
        <div className="error left-aligned">Password does not meet the requirements</div>
      )}
    </>
  );
};

export default PasswordInputField;
