import React from 'react';
import {Typography} from '@mui/material';

const HasLeftSchoolPage = ({hasLeftSchool, setHasLeftSchool, autoFocus = true}) => (
  <>
    <Typography variant="h4" gutterBottom>
      Has your young person left school?
    </Typography>
    <Typography variant="body2" paragraph>
      We use this to calculate your stage in the transitions path.
    </Typography>
    <div className="yes-no-buttons">
      <button
        className={`question-button dark ${hasLeftSchool === true ? 'selected' : ''}`}
        name="left-school-yes"
        type="button"
        onClick={() => {
          setHasLeftSchool(true);
        }}>
        Yes
      </button>
      <button
        className={`question-button dark ${hasLeftSchool === false ? 'selected' : ''}`}
        name="left-school-no"
        type="button"
        autoFocus={autoFocus}
        onClick={() => {
          setHasLeftSchool(false);
        }}>
        No
      </button>
    </div>
  </>
);

export default HasLeftSchoolPage;
