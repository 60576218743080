import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {deepmerge} from '@mui/utils';
import {getSharedTheme, white, pink, yellow, getSharedComponentStyles} from '../sharedTheme';

const primaryPale = '#bfd5d9';
const primaryLight = '#aecfee';
const primaryBase = '#4b7981';
const primaryDark = '#4b7981';
const secondaryPale = '#fad5c7';
const secondaryLight = '#ed6f43';
const secondaryBase = '#ed6f43';
const secondaryDark = '#ed6f43';
const buttonContainedHover = '#ee7a51';
const buttonContainedFocus = '#f1916f';
const buttonContainedActive = '#f29d7f';
const buttonDisabled = '#f3a58a';
const buttonDisabledText = '#727372';
const feedbackColor = '#cfaeac';

const getCustomTheme = (mode) => {
  const sharedTheme = getSharedTheme(mode);
  let theme = createTheme(
    deepmerge(sharedTheme, {
      palette: {
        mode,
        primary: {
          pale: primaryPale,
          light: primaryLight,
          main: primaryBase,
          dark: primaryDark,
          contrastText: white,
        },
        secondary: {
          pale: secondaryPale,
          light: secondaryLight,
          main: secondaryBase,
          dark: secondaryDark,
          contrastText: white,
        },
        feedbackColor,
        pink: pink,
        yellow: yellow,
        notSure: {
          pale: '#f6f1df',
          light: '#e4cf95',
          base: '#d5b354',
        },
        ratings: {
          yes: '#ced84c',
          no: '#e45d59',
        },
      },
      components: getSharedComponentStyles(
        primaryPale,
        primaryDark,
        secondaryBase,
        buttonContainedHover,
        buttonContainedFocus,
        buttonContainedActive,
        buttonDisabled,
        buttonDisabledText
      ),
    })
  );

  theme = responsiveFontSizes(theme);
  return theme;
};

export default getCustomTheme;
