import React, {useState} from 'react';

import {useAuth} from './AuthProvider';
import {useLocation} from 'react-router-dom';

import PasswordInputField from '../Util/PasswordInputField';
import {EMAIL_VALIDATION_REGEX_STRING} from '../Util/constants';
import {Button, Container, Paper, Typography} from '@mui/material';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  const {submitPasswordReset} = useAuth();
  const [formData, setFormData] = useState({
    email: undefined,
    password: '',
    passwordConfirmation: '',
  });

  const emailUnset = formData.email === undefined || formData.email === '';
  const passwordUnset = formData.password === undefined || formData.password === '';
  const passwordConfirmationUnset =
    formData.passwordConfirmation === undefined || formData.passwordConfirmation === '';

  const buttonDisabled = emailUnset || passwordUnset || passwordConfirmationUnset;

  let query = useQuery();
  const token = query.get('token');

  const passwordsDoNotMatch = formData.password !== formData.passwordConfirmation;

  const handleSubmit = async (event) => {
    event.preventDefault();
    submitPasswordReset(token, formData.email, formData.password, formData.passwordConfirmation);
  };

  return (
    <Container maxWidth="sm" sx={{py: 6}}>
      <Paper sx={{mt: 4, p: 4, borderRadius: 5}}>
        <Typography variant="h2" component="h1" gutterBottom sx={{mt: 1}} align="center">
          Reset your password
        </Typography>
        <form className="center-content" onSubmit={handleSubmit}>
          <h1 className="header-4 auth-text"></h1>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            autoFocus
            type="email"
            pattern={EMAIL_VALIDATION_REGEX_STRING}
            onChange={(event) => setFormData({...formData, email: event.target.value})}
            placeholder="Email"
          />
          <PasswordInputField
            id="new-password"
            autoComplete="new-password"
            value={formData.password}
            label="New password"
            placeholder="Enter new password"
            shouldError={passwordsDoNotMatch}
            onChangeSetter={(newValue) => setFormData({...formData, password: newValue})}
          />
          {passwordsDoNotMatch && <div className="error">Passwords do not match</div>}
          <PasswordInputField
            id="confirm-new-password"
            autoComplete="new-password"
            value={formData.passwordConfirmation}
            label="Confirm password"
            placeholder="Confirm new password"
            shouldError={passwordsDoNotMatch}
            onChangeSetter={(newValue) =>
              setFormData({...formData, passwordConfirmation: newValue})
            }
            showRules={false}
          />
          {passwordsDoNotMatch && <div className="error">Passwords do not match</div>}
          <div className="form-button">
            <Button variant="contained" type="submit" disabled={buttonDisabled}>
              Save
            </Button>
          </div>
        </form>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
