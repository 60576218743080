import React, {useState} from 'react';
import {Route} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import {SentryRoutes} from '../Sentry';

import {useAuth} from '../Auth/AuthProvider';
import PasswordInputField from '../Util/PasswordInputField';
import PasswordRules from '../Util/PasswordRules';
import EditComponentWithSaveButton from './EditComponentWithSaveButton';
import PhoneNumberInputField from '../Util/PhoneNumberInputField';
import NotificationPreferences from './NotificationPreferences';

const EditMyAccountDetails = ({profileDetails}) => {
  const {authState, updateUser, updatePassword} = useAuth();

  const [name, setName] = useState(authState.user.name);
  const [phoneNumber, setPhoneNumber] = useState(
    authState.user.phone_number ? authState.user.phone_number.replace('+44', '') : ''
  );
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const passwordsDoNotMatch = passwordData.newPassword !== passwordData.confirmNewPassword;

  const checkPasswordMeetsRequirements = (password) => {
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
    return regex.test(password);
  };

  return (
    <SentryRoutes>
      <Route
        path={'name'}
        element={
          <EditComponentWithSaveButton
            buttonDisabled={name === '' || name === authState.user.name}
            onClickSave={() => updateUser({name})}>
            <Typography variant="h2" gutterBottom>
              What's your name?
            </Typography>
            <input
              autoFocus
              type="text"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder="Name"
            />
          </EditComponentWithSaveButton>
        }
      />
      <Route
        path={'phone-number'}
        element={
          <EditComponentWithSaveButton
            buttonDisabled={phoneNumber === authState.user.phone_number?.replace('+44', '')}
            onClickSave={() => updateUser({phone_number: phoneNumber})}>
            <Typography variant="h2" gutterBottom>
              What's your mobile number?
            </Typography>
            <Typography paragraph gutterBottom>
              We use this to send you reminder notifications from Compass. It is never shared with
              anyone else and will not be used to contact you for any other reason.
            </Typography>
            <PhoneNumberInputField
              value={phoneNumber}
              onChangeSetter={(newValue) => setPhoneNumber(newValue)}
            />
          </EditComponentWithSaveButton>
        }
      />
      <Route path={'notifications'} element={<NotificationPreferences />} />
      <Route
        path={'password'}
        element={
          <EditComponentWithSaveButton
            buttonDisabled={
              passwordData.oldPassword === '' ||
              passwordData.oldPassword === undefined ||
              passwordsDoNotMatch ||
              !checkPasswordMeetsRequirements(passwordData.newPassword)
            }
            onClickSave={() =>
              updatePassword({
                oldPassword: passwordData.oldPassword,
                password: passwordData.newPassword,
                password_confirmation: passwordData.confirmNewPassword,
              })
            }
            dialogMessage="Please make sure you have entered the correct password.">
            <Typography variant="h2" gutterBottom>
              Change Password
            </Typography>
            <Box sx={{mb: 2}}>
              <PasswordRules />
            </Box>
            <Box sx={{mb: 1}}>
              <label htmlFor="old-password">Current password</label>
              <input
                id="old-password"
                autoComplete="off"
                autoFocus
                type="password"
                minLength="8"
                required
                onChange={(event) =>
                  setPasswordData({...passwordData, oldPassword: event.target.value})
                }
                placeholder="Current password"
              />
            </Box>
            <Box sx={{mb: 1}}>
              <PasswordInputField
                id="new-password"
                autoComplete="new-password"
                value={passwordData.newPassword}
                label="New password"
                placeholder="New password"
                shouldError={passwordsDoNotMatch}
                onChangeSetter={(newValue) =>
                  setPasswordData({...passwordData, newPassword: newValue})
                }
                showRules={false}
              />
              {passwordsDoNotMatch && <div className="error">Passwords do not match</div>}
            </Box>
            <Box sx={{mb: 1}}>
              <PasswordInputField
                id="confirm-new-password"
                autoComplete="new-password"
                value={passwordData.confirmNewPassword}
                label="Confirm new password"
                placeholder="Confirm new password"
                shouldError={passwordsDoNotMatch}
                onChangeSetter={(newValue) =>
                  setPasswordData({...passwordData, confirmNewPassword: newValue})
                }
                showRules={false}
              />
              {passwordsDoNotMatch && <div className="error">Passwords do not match</div>}
            </Box>
          </EditComponentWithSaveButton>
        }
      />
      {profileDetails}
    </SentryRoutes>
  );
};

export default EditMyAccountDetails;
