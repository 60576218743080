import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DialogToolbarBackButton = ({onBackClick}) => (
  <IconButton edge="start" onClick={onBackClick} aria-label="back" data-testid="dialog-back-button">
    <ArrowBackIcon />
  </IconButton>
);

export default DialogToolbarBackButton;
