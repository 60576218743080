import React from 'react';

import {useParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Container, Typography, Paper} from '@mui/material';

import useEvaluationHistory from '../../Queries/useEvaluationHistory';
import PageHeader from '../../Util/PageHeader';
import feedbackIcon from '../../img/EvaluationLight.svg';
import {longDateFormatter} from '../../Util/constants';
import ProPreviousEvaluationQuestionResponse from './ProPreviousEvaluationQuestionResponse';

const ProPreviousEvaluation = () => {
  const {evaluationId} = useParams();
  const theme = useTheme();
  const {feedbackColor} = theme.palette;
  const {data: evaluations, isLoading, isError} = useEvaluationHistory();

  if (isLoading) {
    return <div>Loading</div>;
  }
  if (isError) {
    return <div>Error</div>;
  }

  const evaluation = evaluations.find((evaluation) => evaluation.id === parseInt(evaluationId));

  return (
    <>
      <PageHeader
        name="Feedback History"
        color={feedbackColor}
        backTo="/feedback/about"
        icon={feedbackIcon}
      />
      <Container maxWidth="md" sx={{paddingTop: {md: 5, xs: 2}}}>
        <Typography variant="h3" gutterBottom>
          What you said on {longDateFormatter.format(new Date(evaluation.completed_at))}
        </Typography>
        {evaluation.responses.map((response, index) => (
          <ProPreviousEvaluationQuestionResponse
            response={response}
            key={`evaluation-response-${index}`}
          />
        ))}
        {evaluation.other_comments && (
          <Paper sx={{padding: 4, my: 2}}>
            <Typography paragraph>You also said...</Typography>
            <Typography sx={{fontStyle: 'italic'}}>{evaluation.other_comments}</Typography>
          </Paper>
        )}
      </Container>
    </>
  );
};

export default ProPreviousEvaluation;
