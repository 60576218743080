import React, {useState, useRef} from 'react';
import {Button, Container, Paper, Typography} from '@mui/material';

import {useAuth} from './AuthProvider';
import BackLink from '../Util/BackLink';
import PasswordInputField from '../Util/PasswordInputField';
import PhoneNumberInputField from '../Util/PhoneNumberInputField';
import {EMAIL_VALIDATION_REGEX_STRING} from '../Util/constants';

const Register = ({
  title,
  nameHeader,
  emailHeader,
  phoneHeader,
  passwordHeader,
  showPhoneField = false,
}) => {
  const {register} = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    password: '',
  });

  const registrationFields = showPhoneField
    ? ['name', 'email', 'phone_number', 'password']
    : ['name', 'email', 'password'];
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const currentFieldKey = registrationFields[currentFieldIndex];
  const buttonDisabled =
    currentFieldIndex !== registrationFields.indexOf('consent') &&
    currentFieldKey !== 'phone_number' &&
    (!formData[currentFieldKey] || formData[currentFieldKey] === '');

  const shouldShowField = (field) => registrationFields.indexOf(field) === currentFieldIndex;

  const formEl = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formEl.current.reportValidity() === false) return;

    if (currentFieldIndex < registrationFields.length - 1) {
      setCurrentFieldIndex(currentFieldIndex + 1);
    } else {
      register(formData);
    }
  };

  const handlePrevButtonPress = () => {
    setCurrentFieldIndex(currentFieldIndex - 1);
    // reset password if user navigates away from password screen
    if (currentFieldKey === 'password') {
      setFormData({...formData, password: undefined});
    }
  };

  return (
    <Container maxWidth="sm" sx={{py: 4}}>
      <BackLink to="/" />
      <Paper sx={{mt: 4, p: 4, borderRadius: 5}}>
        <Typography variant="h2" component="h1" gutterBottom sx={{mt: 1}} align="center">
          {title}
        </Typography>
        <form ref={formEl} onSubmit={handleSubmit}>
          {shouldShowField('name') && (
            <>
              {nameHeader}
              <input
                autoFocus
                type="text"
                required
                value={formData.name}
                onChange={(event) => setFormData({...formData, name: event.target.value})}
                placeholder="Name"
              />
            </>
          )}
          {shouldShowField('email') && (
            <>
              {emailHeader}
              <input
                autoFocus
                value={formData.email}
                type="email"
                pattern={EMAIL_VALIDATION_REGEX_STRING}
                required
                onChange={(event) => setFormData({...formData, email: event.target.value})}
                placeholder="Email"
              />
            </>
          )}
          {shouldShowField('phone_number') && (
            <>
              {phoneHeader}
              <PhoneNumberInputField
                value={formData.phone_number}
                onChangeSetter={(newValue) => setFormData({...formData, phone_number: newValue})}
              />
            </>
          )}
          {shouldShowField('password') && (
            <>
              {passwordHeader}
              <PasswordInputField
                id="new-password"
                autoComplete="new-password"
                autoFocus
                value={formData.password}
                placeholder="Password"
                onChangeSetter={(newValue) => setFormData({...formData, password: newValue})}
              />
            </>
          )}
          <div
            className={currentFieldIndex > 0 ? 'register-buttons' : 'form-button'}
            style={{marginTop: 36}}>
            {currentFieldIndex > 0 && (
              <Button variant="outlined" color="secondary" onClick={handlePrevButtonPress}>
                Previous
              </Button>
            )}
            <Button variant="contained" color="secondary" type="submit" disabled={buttonDisabled}>
              {currentFieldIndex < registrationFields.length - 1 ? 'Next' : 'Finish'}
            </Button>
          </div>
        </form>
      </Paper>
    </Container>
  );
};

export default Register;
