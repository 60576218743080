import React, {useState} from 'react';
import {Button, Chip, IconButton, Dialog, DialogTitle, DialogContent, Divider} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import DateInputPage from '../Onboarding/OnboardingPages/DateInputPage';
import {useOnboarding} from '../Onboarding/OnboardingProvider';
import {MIN_YEAR_OF_BIRTH_PARENT_TOOL, MAX_YEAR_OF_BIRTH_PARENT_TOOL} from '../Util/constants';
import HasLeftSchoolPage from '../Parent/Onboarding/HasLeftSchoolPage';
import SchoolYearPage from '../Parent/Onboarding/SchoolYearPage';
import HasExpectedLeavingDatePage from '../Parent/Onboarding/HasExpectedLeavingDatePage';
import ExpectedLeavingDatePage from '../Parent/Onboarding/ExpectedLeavingDatePage';

const FindCurrentStageDialog = ({open, handleClose, handleResult}) => {
  const {
    calculateTransitionStageDates,
    calculateCurrentTransitionStage,
    calculatePotentialExpectedLeavingDates,
  } = useOnboarding();
  const [monthOfBirth, setMonthOfBirth] = useState();
  const [yearOfBirth, setYearOfBirth] = useState();
  const [hasLeftSchool, setHasLeftSchool] = useState();
  const [schoolYear, setSchoolYear] = useState();
  const [hasExpectedLeavingDate, setHasExpectedLeavingDate] = useState();
  const [expectedLeavingDate, setExpectedLeavingDate] = useState({term: '', year: ''});

  const handleExpectedLeavingDate = async (expectedLeavingDate) => {
    const stageDates = await calculateTransitionStageDates(
      expectedLeavingDate.term,
      expectedLeavingDate.year
    );
    const currentStage = await calculateCurrentTransitionStage(
      expectedLeavingDate.term,
      expectedLeavingDate.year
    );
    handleResult(stageDates, currentStage);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{m: 0, p: 2}}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Chip label="1" color="secondary" />
        <DateInputPage
          title="When was your young person born?"
          subtitle="We use this to calculate their stage in the transitions path. We just need the month and year."
          month={monthOfBirth ?? ''}
          setMonth={(month) => setMonthOfBirth(month)}
          year={yearOfBirth ?? ''}
          setYear={(year) => setYearOfBirth(year)}
          minYear={MIN_YEAR_OF_BIRTH_PARENT_TOOL}
          maxYear={MAX_YEAR_OF_BIRTH_PARENT_TOOL}
        />
        {monthOfBirth &&
          monthOfBirth >= 1 &&
          monthOfBirth <= 12 &&
          yearOfBirth &&
          yearOfBirth >= MIN_YEAR_OF_BIRTH_PARENT_TOOL &&
          yearOfBirth <= MAX_YEAR_OF_BIRTH_PARENT_TOOL && (
            <>
              <Divider sx={{my: 2}} />
              <Chip label="2" color="secondary" />
              <HasLeftSchoolPage
                autoFocus={false}
                hasLeftSchool={hasLeftSchool}
                setHasLeftSchool={setHasLeftSchool}
              />
              {hasLeftSchool === true ? (
                <>
                  <>
                    <Divider sx={{my: 2}} />
                    <Chip label="3" color="secondary" />
                    <ExpectedLeavingDatePage
                      hasLeftSchool={hasLeftSchool}
                      dob={{month: monthOfBirth, year: yearOfBirth}}
                      schoolYear={schoolYear}
                      expectedLeavingDate={expectedLeavingDate}
                      setExpectedLeavingDate={setExpectedLeavingDate}
                    />
                    {expectedLeavingDate.term && expectedLeavingDate.year && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleExpectedLeavingDate(expectedLeavingDate)}>
                        Find Current Stage
                      </Button>
                    )}
                  </>
                </>
              ) : hasLeftSchool === false ? (
                <>
                  <Divider sx={{my: 2}} />
                  <Chip label="3" color="secondary" />
                  <SchoolYearPage
                    month={monthOfBirth}
                    year={yearOfBirth}
                    schoolYear={schoolYear}
                    setSchoolYear={setSchoolYear}
                  />
                  {schoolYear && (
                    <>
                      <Divider sx={{my: 2}} />
                      <Chip label="4" color="secondary" />
                      <HasExpectedLeavingDatePage
                        autoFocus={false}
                        hasExpectedLeavingDate={hasExpectedLeavingDate}
                        setHasExpectedLeavingDate={(has) => {
                          if (!has) {
                            calculatePotentialExpectedLeavingDates(
                              monthOfBirth,
                              yearOfBirth,
                              schoolYear,
                              !hasLeftSchool //  if hasn't left school yet, hide past dates
                            ).then((possibleLeavingDates) => {
                              const earliestPossibleLeavingDate = possibleLeavingDates[0];
                              handleExpectedLeavingDate(earliestPossibleLeavingDate);
                            });
                          } else {
                            setHasExpectedLeavingDate(has);
                          }
                        }}
                      />
                      {hasExpectedLeavingDate === true ? (
                        <>
                          <Divider sx={{my: 2}} />
                          <Chip label="5" color="secondary" />
                          <ExpectedLeavingDatePage
                            hasLeftSchool={hasLeftSchool}
                            dob={{month: monthOfBirth, year: yearOfBirth}}
                            schoolYear={schoolYear}
                            expectedLeavingDate={expectedLeavingDate}
                            setExpectedLeavingDate={setExpectedLeavingDate}
                          />
                          {expectedLeavingDate.term && expectedLeavingDate.year && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleExpectedLeavingDate(expectedLeavingDate)}>
                              Find Current Stage
                            </Button>
                          )}
                        </>
                      ) : null}
                    </>
                  )}
                </>
              ) : null}
            </>
          )}
      </DialogContent>
    </Dialog>
  );
};

export default FindCurrentStageDialog;
