import React from 'react';
import {Route, Navigate} from 'react-router-dom';
import {SentryRoutes} from '../../Sentry';

import ProEvaluationAbout from './ProEvaluationAbout';
import ProEvaluationHistory from './ProEvaluationHistory';
import ProPreviousEvaluation from './ProPreviousEvaluation';

import ProEvaluation from './ProEvaluation';

export default function ProEvaluationIndex() {
  return (
    <SentryRoutes>
      <Route path="about" element={<ProEvaluationAbout />} />
      <Route path="/:evaluationId/*" element={<ProEvaluation />} />
      <Route path="history" element={<ProEvaluationHistory />} />
      <Route path="history/:evaluationId" element={<ProPreviousEvaluation />} />
      <Route path="*" element={<Navigate to="about" replace />} />
    </SentryRoutes>
  );
}
