import React from 'react';
import {useParams, Link as RouterLink} from 'react-router-dom';
import {
  Breadcrumbs,
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Link,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@mui/material';
import {Circle} from '@mui/icons-material';

import useYoungPersonCategories from '../Queries/useYoungPersonCategories';
import {NUM_TRANSITION_TOPIC_LINKS} from '../Util/constants';
import {YOUNG_PERSON_CATEGORY_TOPIC_ICONS} from '../YoungPerson/transitionIcons';

const ProViewTopic = () => {
  const {categoryId, topicId} = useParams();
  const {data: categories, isLoading, error} = useYoungPersonCategories();
  const category = categories?.find((category) => category.id === parseInt(categoryId));
  const topic = category?.topics.find((topic) => topic.id === parseInt(topicId));
  const subtopics = topic?.subtopics ? topic?.subtopics?.split('~') : [];

  let topicLinks = [];
  if (topic) {
    for (let i = 0; i < NUM_TRANSITION_TOPIC_LINKS; i++) {
      const linkNumber = i + 1;
      const link = topic[`link_${linkNumber}`];
      const title = topic[`link_${linkNumber}_title`];
      if (link) {
        topicLinks.push({link, title});
      }
    }
  }
  if (isLoading) {
    return <div>Loading</div>;
  }
  if (error) {
    return <div>Error</div>;
  }
  return (
    <Container sx={{mt: 2}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to="/young-person-categories">
          Young People
        </Link>
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to={`/young-person-categories/${categoryId}`}>
          {category.name}
        </Link>
        <Typography color="text.primary">{topic.name}</Typography>
      </Breadcrumbs>
      <Box display="flex" justifyContent="center">
        <Card sx={{p: 4, maxWidth: '80%'}}>
          <CardHeader
            title={topic.name}
            avatar={
              <img
                src={YOUNG_PERSON_CATEGORY_TOPIC_ICONS[topic.key]}
                width={42}
                height={42}
                alt=""
              />
            }
          />
          <CardContent>
            <Typography>{topic.description}</Typography>
            <List>
              {subtopics.map((subtopic, index) => (
                <ListItem key={`subtopic-${index}`} disableGutters>
                  <ListItemIcon>
                    <Circle fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>{subtopic}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </CardContent>
          <CardActions>
            <List subheader={<ListSubheader disableSticky>Helpful Links</ListSubheader>}>
              {topicLinks.map((link, index) => (
                <ListItem key={`link-${index}`}>
                  <Link href={link.link} target="_blank" rel="noreferrer">
                    {link.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};
export default ProViewTopic;
