import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Container, Typography} from '@mui/material';

import feedbackIcon from '../../img/EvaluationLight.svg';
import useEvaluationHistory from '../../Queries/useEvaluationHistory';
import PageHeader from '../../Util/PageHeader';
import {longDateFormatter} from '../../Util/constants';
import PreviousEvaluationRow from '../../Evaluation/PreviousEvaluationRow';

export default function ProEvaluationHistory() {
  const navigate = useNavigate();
  const theme = useTheme();
  const {feedbackColor} = theme.palette;
  const {data: evaluations, isLoading, isError} = useEvaluationHistory();

  if (isLoading) {
    return <div>Loading</div>;
  }
  if (isError) {
    return <div>Error</div>;
  }

  return (
    <>
      <PageHeader
        name="Feedback History"
        color={feedbackColor}
        backTo="/feedback/about"
        icon={feedbackIcon}
      />
      <Container maxWidth="md" sx={{paddingTop: {md: 5, xs: 2}}}>
        <Typography variant="h3" gutterBottom>
          Previous Responses
        </Typography>
        {evaluations.length > 0 ? (
          evaluations.map((evaluation) => {
            const evaluationDate = longDateFormatter.format(new Date(evaluation.completed_at));
            return (
              <PreviousEvaluationRow
                key={`evaluation-${evaluation.id}`}
                title={
                  evaluation.completed_at ? `What you said on ${evaluationDate}` : 'In progress...'
                }
                onClick={() => navigate(evaluation.id.toString())}
              />
            );
          })
        ) : (
          <Typography paragraph>You have not completed any feedback yet.</Typography>
        )}
      </Container>
    </>
  );
}
