import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Link} from 'react-router-dom';
import {Button, Container, IconButton} from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import {Box} from '@mui/system';

import {useDarkModeTheme} from '../DarkModeThemeProvider';

const UnauthenticatedNavBar = ({logoLight, logoDark}) => {
  const darkModeTheme = useDarkModeTheme();
  return (
    <AppBar position="sticky" color="default">
      <Container maxWidth="md">
        <Toolbar disableGutters>
          <Box sx={{flex: 1}}>
            <IconButton to="/" component={Link}>
              <img
                src={darkModeTheme.isDarkMode ? logoDark : logoLight}
                height={35}
                alt="Compass Landing Page"
              />
            </IconButton>
          </Box>
          {darkModeTheme && (
            <IconButton
              onClick={() => darkModeTheme.setIsDarkMode((current) => !current)}
              sx={{mr: 1}}>
              <Brightness4Icon />
            </IconButton>
          )}
          <Button to="/login" component={Link} variant="outlined" color="secondary">
            Log In
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default UnauthenticatedNavBar;
