import React, {createContext, useEffect} from 'react';

import {useLocation} from 'react-router-dom';

export const ViewportContext = createContext();

function ViewportProvider({children}) {
  const location = useLocation();

  const setCustomVhProperty = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  };

  useEffect(() => {
    setCustomVhProperty();

    window.addEventListener('resize', setCustomVhProperty);

    return () => {
      window.removeEventListener('resize', setCustomVhProperty);
    };
  }, []);

  useEffect(() => {
    // When we get navigation events, scroll to top:
    document.documentElement.scrollTop = 0;
  }, [location.pathname]);

  return <ViewportContext.Provider value={{}}>{children}</ViewportContext.Provider>;
}

export {ViewportProvider};
