import React, {useState} from 'react';
import {useParams, useNavigate, Link as RouterLink} from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Container,
  Chip,
  Breadcrumbs,
  Link,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import useKeyDuties from '../Queries/useKeyDuties';
import useProSectors from '../Queries/useProSectors';
import {useAuth} from '../Auth/AuthProvider';
import {useOnboarding} from '../Onboarding/OnboardingProvider';

const ProKeyDutiesGrid = ({keyDuties}) => {
  const navigate = useNavigate();
  const {data: sectors, isLoading, error} = useProSectors();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error</div>;
  }

  return (
    <Grid container alignItems="stretch" spacing={2}>
      {keyDuties.map((keyDuty) => (
        <Grid xs={12} md={4} key={`key-duty-${keyDuty.id}`}>
          <Card sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <CardHeader
              title={keyDuty.title}
              subheader={
                <>
                  <Chip
                    sx={{mt: 2}}
                    label={keyDuty.obligation}
                    variant={keyDuty.obligation === 'Must (duty)' ? 'filled' : 'outlined'}
                    color={keyDuty.obligation === 'Must (duty)' ? 'secondary' : 'primary'}
                  />
                  <Chip
                    sx={{mt: 2, ml: 1}}
                    label={
                      sectors.find((sector) => sector.id === keyDuty.professional_sector_id)
                        ?.name ?? 'All sectors'
                    }
                  />
                </>
              }
            />
            <CardContent sx={{flex: 1}} />
            <CardActions>
              <Button
                data-testid={keyDuty.title}
                size="small"
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigate(`duty/${keyDuty.id}`)}>
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const ProKeyDutiesStage = () => {
  const {data: keyDuties, isLoading, error} = useKeyDuties();
  const {stageId} = useParams();
  const {authState} = useAuth();
  const {onboardingState} = useOnboarding();
  const [isFiltering, setIsFiltering] = useState(true);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error</div>;
  }

  const keyDutiesForCurrentStage = keyDuties.filter(
    (keyDuty) => keyDuty.stage_id === parseInt(stageId)
  );

  // if we filtering, we only want to show the key duties for the user's professional sector
  // if we are not filtering, we want to show all key duties for the stage
  const keyDutiesForCurrentStageAndSector = keyDutiesForCurrentStage.filter((keyDuty) =>
    isFiltering
      ? keyDuty.professional_sector_id === authState.user.profile.professional_sector_id ||
        keyDuty.professional_sector_id === null
      : true
  );

  const keyDutiesForAllStages = keyDuties.filter((keyDuty) => keyDuty.stage_id === null);
  const keyDutiesForAllStagesForUserProfessionalSector = keyDutiesForAllStages.filter((keyDuty) =>
    isFiltering
      ? keyDuty.professional_sector_id === authState.user.profile.professional_sector_id ||
        keyDuty.professional_sector_id === null
      : true
  );

  const stage = onboardingState?.stages?.find((stage) => stage.id === parseInt(stageId));

  return (
    <Container sx={{mt: 2}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to="/key-duties">
          Key Duties
        </Link>
        <Typography color="text.primary">{stage?.medium_label}</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {md: 'row', sm: 'column-reverse', xs: 'column-reverse'},
          justifyContent: 'space-between',
        }}>
        <Box>
          <Typography variant="h3" gutterBottom>
            {stage?.medium_label} duties
          </Typography>
          <Typography paragraph>
            {keyDutiesForCurrentStageAndSector.length === 0
              ? 'You have no key duties for this stage.'
              : isFiltering
              ? 'These are the key duties for your sector.'
              : 'These are the key duties for all sectors.'}
          </Typography>
        </Box>
        <FormGroup>
          <FormControlLabel
            sx={{p: 1}}
            control={
              <Switch
                data-testid="filter-key-duties-switch"
                checked={!isFiltering}
                onChange={() => setIsFiltering(!isFiltering)}
              />
            }
            label="Show duties for other sectors"
          />
        </FormGroup>
      </Box>
      <ProKeyDutiesGrid keyDuties={keyDutiesForCurrentStageAndSector} />
      <Divider sx={{my: 2}} />
      <Box sx={{my: 2}}>
        <Typography variant="h3" gutterBottom>
          All stage duties
        </Typography>
        <Typography paragraph>
          These duties apply to all stages of the transition process.
        </Typography>
      </Box>
      <ProKeyDutiesGrid keyDuties={keyDutiesForAllStagesForUserProfessionalSector} />
    </Container>
  );
};

export default ProKeyDutiesStage;
