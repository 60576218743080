import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useKeyDuties = () => {
  const {data, isLoading, error} = useQuery({
    queryKey: ['key-duties'],
    queryFn: async () => await axios.get('/api/key-duties').then((res) => res.data),
    staleTime: Infinity,
  });

  return {data, isLoading, error};
};

export default useKeyDuties;
