import React, {useState} from 'react';
import {useTheme} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {Button, Container, Typography} from '@mui/material';

import PageHeader from '../Util/PageHeader';
import AccountHeaderIcon from '../img/account-header.svg';
import DeleteAccountDialog from './DeleteAccountDialog';
import {useAuth} from '../Auth/AuthProvider';

const DeleteAccount = () => {
  const {deleteUser, isParent} = useAuth();
  const theme = useTheme();

  const {dark, contrastText} = theme.palette.primary;

  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);
  const [shouldShowProgressIndicator, setShouldShowProgressIndicator] = useState(false);

  const handleDeleteAccount = async () => {
    setShouldShowProgressIndicator(true);
    await deleteUser();
    setShouldShowProgressIndicator(false);
  };

  return shouldShowProgressIndicator ? (
    <div className="center-content">
      <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress size={100} color="secondary" />
      </div>
    </div>
  ) : (
    <>
      <PageHeader
        color={dark}
        name={'My Account'}
        icon={AccountHeaderIcon}
        textColor={contrastText}
        backTo="/my-account"
      />
      <Container maxWidth="md" sx={{py: 4}}>
        <Typography variant="h2" gutterBottom>
          Delete My Account
        </Typography>
        <Typography paragraph>
          You are about to delete your account, which means you will no longer be able to log onto
          Compass or access the information within it.
        </Typography>
        <Typography>What else you should know:</Typography>
        <ul>
          <li>
            <Typography variant="body2">
              Your name and email address will be removed from our system.
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {isParent
                ? "Your responses about your transitions experience, and your young person's transitions progress, will be retained for research purposes."
                : 'We will still be able to see what you have said is going well, and what you think needs to get better. But no one will know you gave those answers.'}
            </Typography>
          </li>
        </ul>
        {/* <div>
          Please tick this box if you want to withdraw your consent for the above information to be
          processed.
        </div>{' '} */}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShouldShowDeleteDialog(true)}
          style={{float: 'right'}}>
          Delete Account
        </Button>
      </Container>
      <DeleteAccountDialog
        isVisible={shouldShowDeleteDialog}
        closeDialog={() => setShouldShowDeleteDialog(false)}
        handleDeleteAccount={handleDeleteAccount}
      />
    </>
  );
};

export default DeleteAccount;
