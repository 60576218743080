import {Box, Typography} from '@mui/material';
import React from 'react';
import {Container} from '@mui/system';

import BackLink from './BackLink';

const PageHeader = ({color, name, icon, textColor = 'black', children, backTo, backText}) => (
  <Box component="header" sx={{backgroundColor: color, pb: 4}} className="page-header">
    <Box sx={{mt: 3}}>
      {backTo && <BackLink color={textColor} to={backTo} backText={backText} />}
    </Box>
    <Container maxWidth="md">
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        {/* If there's an icon, width is 80 on xs screens and 60 on sm */}
        <Typography
          variant="h2"
          component="h1"
          sx={{
            width: icon ? {xs: '80%', sm: '60%'} : '100%',
            color: textColor ? textColor : undefined,
          }}>
          {name}
        </Typography>
        {icon && <img aria-hidden="true" src={icon} height={90} width={90} />}
      </Box>
      {children}
    </Container>
  </Box>
);

export default PageHeader;
