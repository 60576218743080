import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Box, Button, Container, Paper, Typography} from '@mui/material';

import {useAuth} from './AuthProvider';
import BackLink from '../Util/BackLink';
import {EMAIL_VALIDATION_REGEX_STRING} from '../Util/constants';

const Login = ({title = 'Login', image}) => {
  const {login} = useAuth();
  const [loginFormData, setLoginFormData] = useState({
    email: undefined,
    password: undefined,
  });
  const navigate = useNavigate();

  const emailUnset = loginFormData.email === undefined || loginFormData.email === '';
  const passwordUnset = loginFormData.password === undefined || loginFormData.password === '';
  const buttonDisabled = emailUnset || passwordUnset;

  return (
    <Container maxWidth="sm" sx={{py: 4}}>
      <BackLink to="/" />
      <Paper sx={{mt: 4, p: 4, borderRadius: 5}}>
        <Typography variant="h2" component="h1" gutterBottom sx={{mt: 1}} align="center">
          {title}
        </Typography>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            login(loginFormData.email, loginFormData.password);
          }}>
          {image && <Box sx={{textAlign: 'center', my: 4}}>{image}</Box>}
          <div className="text-field">
            <label htmlFor="login-email">Email</label>
            <input
              autoFocus
              id="login-email"
              type="email"
              pattern={EMAIL_VALIDATION_REGEX_STRING}
              onChange={(event) => setLoginFormData({...loginFormData, email: event.target.value})}
              placeholder="Email"
            />
          </div>
          <div className="text-field">
            <label htmlFor="login-password">Password</label>
            <input
              id="login-password"
              autoComplete="off"
              type="password"
              onChange={(event) =>
                setLoginFormData({...loginFormData, password: event.target.value})
              }
              placeholder="Password"
            />
          </div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <Button
              variant="text"
              color="warning"
              component={Link}
              className="forgot-password"
              to="/forgotpassword">
              Forgot your password?
            </Button>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Button variant="contained" color="secondary" type="submit" disabled={buttonDisabled}>
              {title}
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => navigate('/your-data')}>
              Sign Up
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
