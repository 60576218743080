import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {Link, Tooltip} from '@mui/material';

import {DIALOG_URL_PARAMS} from './constants';
import useGlossaryTerms from '../Queries/useGlossaryTerms';
import {isLetter} from './helpers';

const GlossaryAnnotatedText = ({text}, props) => {
  const {data: glossaryItems, isLoading, error} = useGlossaryTerms();
  const [searchParams, setSearchParams] = useSearchParams();

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error</p>;
  }

  // Sort the glossary terms longest-to-shortest (use this later to avoid matching terms inside other terms)
  const sortedGlossaryItems = glossaryItems.sort(
    (itemA, itemB) => itemB.term.length - itemA.term.length
  );

  // get the positions of all matching glossaryItems.term in the text in an object
  // where the key is the term and the value is an array of positions
  const positions = sortedGlossaryItems.reduce((acc, {term}) => {
    let position = text.toLowerCase().indexOf(term.toLowerCase());
    while (position !== -1) {
      // Match only groups of full words - characters immediately before and after the term should not be letters
      // e.g "transitional" shouldn't match "transition"
      // e.g "unreasonable adjustments" shouldn't match "reasonable adjustments"
      const charBefore = text[position - 1];
      const charAfter = text[position + term.length];
      if (!isLetter(charBefore) && !isLetter(charAfter)) {
        // Avoid matching terms inside other terms
        // e.g "ILF Scotland Transition Fund" contains the term "Transition"
        // Terms are sorted in decreasing length, so we can simply check existing matches to ensure we aren't inside them
        const insideExistingMatch = Object.entries(acc)
          .map(
            ([accPosition, accTerm]) =>
              position >= accPosition && position <= accPosition + accTerm.length
          )
          .includes(true);
        if (!insideExistingMatch) {
          acc[`${position}`] = term;
        }
      }
      position = text.toLowerCase().indexOf(term.toLowerCase(), position + term.length);
    }
    return acc;
  }, {});

  // now that we have the positions, and we know the length of the term, we can annotate the text
  // by wrapping the term in a link to the glossary term
  // and wrapping the link in a tooltip with the glossary term description
  // we also need to keep track of the position of the last term we annotated
  // so that we can add the text between the last term and the current term
  // and then add the text after the last term
  let annotatedText = [];
  let lastPosition = 0;
  Object.entries(positions).forEach(([position, term]) => {
    // add the text between the last term and the current term
    annotatedText.push(text.substring(lastPosition, position));
    // add the current term wrapped in a tooltip and link
    annotatedText.push(
      <Tooltip
        key={`${term}-${position}`}
        title={glossaryItems.find((item) => item.term === term).description}>
        <Link
          {...props}
          component="button"
          onClick={() => {
            const newSearchParams = searchParams;
            newSearchParams.set(DIALOG_URL_PARAMS.KEY, DIALOG_URL_PARAMS.GLOSSARY);
            newSearchParams.set(DIALOG_URL_PARAMS.GLOSSARY_TERM, term);
            setSearchParams(newSearchParams);
          }}
          color="glossary.dark"
          sx={{fontWeight: 'bold'}}>
          {term}
        </Link>
      </Tooltip>
    );
    // update the lastPosition to be the end of the current term
    lastPosition = parseInt(position) + term.length;
  });
  // add the text after the last term
  annotatedText.push(text.substring(lastPosition));

  return <>{annotatedText}</>;
};

export default GlossaryAnnotatedText;
