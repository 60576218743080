import React, {createContext, useContext, useEffect, useState} from 'react';

import {useAuth} from '../Auth/AuthProvider';
import useLocalStorage from '../Util/useLocalStorage';

const YoungPersonContext = createContext();

const YOUNG_PERSON_URL = 'api/young-person';

const YoungPersonProvider = ({children}) => {
  const {authState, axios, fetchUser} = useAuth();
  const youngPeople = authState.user.young_people;
  const [selectedYoungPersonID, setSelectedYoungPersonID] =
    useLocalStorage('selectedYoungPersonID');
  const [youngPerson, setYoungPerson] = useState();
  const [isAddingAdditionalYoungPerson, setIsAddingAdditionalYoungPerson] = useState(false);

  useEffect(() => {
    if (authState.user.young_people?.length > 0 && !selectedYoungPersonID) {
      // If there wasn't a selectedYoungPerson ID in localStorage when we booted,
      // default to the first entry in authState.user.young_people
      setSelectedYoungPersonID(authState.user.young_people[0].id);
    }
  }, [selectedYoungPersonID, authState.user.young_people]);

  useEffect(() => {
    if (selectedYoungPersonID) {
      fetchYoungPerson(selectedYoungPersonID);
    }
  }, [selectedYoungPersonID]);

  const fetchYoungPerson = async (youngPersonId) => {
    try {
      const youngPerson = await axios.get(`${YOUNG_PERSON_URL}/${youngPersonId}`);
      // TODO:
      // Originally we decided to map the YP model from snake_case to camelCase, and nest some fields
      // However, this means we have multiple formats for the YoungPerson model
      // (e.g where we read it directly from authState to render the NavBar items)

      // We should store the model exactly as we receive it from the backend,
      // and update any relevant components to expect that format
      const youngPersonData = {
        id: youngPerson.data.id,
        nickname: youngPerson.data.nickname,
        dob: {
          month: youngPerson.data.birth_month,
          year: youngPerson.data.birth_year,
        },
        additionalSupportNeeds: youngPerson.data.additional_support_needs,
        customAdditionalSupportNeeds: youngPerson.data.custom_additional_support_needs.map(
          (need) => need.name
        ),
        schoolType: youngPerson.data.school_type,
        schoolYear: youngPerson.data.current_school_year,
        homeLocalAuthority: youngPerson.data.home_local_authority?.name,
        schoolLocalAuthority: youngPerson.data.school_local_authority?.name,
        expectedLeavingDate: {
          term: youngPerson.data.expected_school_leaving_term,
          year: youngPerson.data.expected_school_leaving_year,
        },
        stage: youngPerson.data.stage,
      };
      setYoungPerson(youngPersonData);
    } catch ({response}) {
      if (response.status === 403 || response.status === 404) {
        // If the young person didn't exist, or we aren't authorized to view it
        // Set the selected ID to undefined, which also clears the localStorage value
        //The useEffect at the top of this provider will default it to the first one in authState
        setSelectedYoungPersonID(undefined);
      }
    }
  };

  const updateYoungPerson = async (youngPersonId, youngPerson) => {
    const updatedYoungPerson = await axios.put(
      `${YOUNG_PERSON_URL}/${youngPersonId}/update`,
      youngPerson
    );
    // Re-fetch the user so that authState.user reflects any changes to young_people
    await fetchUser();
    return updatedYoungPerson;
  };

  const deleteYoungPerson = async (youngPersonId) => {
    const youngPerson = await axios.delete(`${YOUNG_PERSON_URL}/${youngPersonId}/delete`);
    // Re-fetch the user so that authState.user reflects any changes to young_people
    await fetchUser();
    return youngPerson;
  };

  return (
    <YoungPersonContext.Provider
      value={{
        youngPeople,
        youngPerson,
        setSelectedYoungPersonID,
        fetchYoungPerson,
        updateYoungPerson,
        deleteYoungPerson,
        isAddingAdditionalYoungPerson,
        setIsAddingAdditionalYoungPerson,
      }}>
      {children}
    </YoungPersonContext.Provider>
  );
};

export const useYoungPerson = () => useContext(YoungPersonContext);

export default YoungPersonProvider;
