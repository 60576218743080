import React, {useEffect, useState} from 'react';
import {Typography, MenuItem, FormControl, Select, CircularProgress} from '@mui/material';

import {useOnboarding} from '../../Onboarding/OnboardingProvider';

const ExpectedLeavingDatePage = ({
  hasLeftSchool,
  dob,
  schoolYear,
  expectedLeavingDate,
  setExpectedLeavingDate,
}) => {
  const {calculatePotentialExpectedLeavingDates} = useOnboarding();
  const [suggestedExpectedLeavingDates, setSuggestedExpectedLeavingDates] = useState(undefined);
  useEffect(() => {
    calculatePotentialExpectedLeavingDates(
      dob.month,
      dob.year,
      schoolYear,
      !hasLeftSchool, // If the user hasn't left school yet, hide past dates
      hasLeftSchool // If the user has left school already, hide future dates
    ).then((potentialLeavingDates) => setSuggestedExpectedLeavingDates(potentialLeavingDates));
  }, [hasLeftSchool]);
  const currentSelectValue =
    expectedLeavingDate.term !== '' && expectedLeavingDate.year !== ''
      ? `${expectedLeavingDate.term} ${expectedLeavingDate.year}`
      : '';
  return (
    <>
      <Typography variant="h4" gutterBottom>
        {hasLeftSchool
          ? 'When did your young person leave school?'
          : 'What is this expected leaving date?'}
      </Typography>
      <Typography variant="body2" paragraph>
        You can edit this date in your profile settings.
      </Typography>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {suggestedExpectedLeavingDates !== undefined ? (
          <FormControl fullWidth sx={{my: 2, mr: 2}}>
            <Select
              name="expected-leaving-date"
              labelId="expected-leaving-date-label"
              value={currentSelectValue}
              displayEmpty
              onChange={(event) => {
                const selectedExpectedLeavingDate = event.target.value.split(' ');
                if (selectedExpectedLeavingDate.length > 1) {
                  const newExpectedLeavingDate = {
                    term: selectedExpectedLeavingDate[0],
                    year: parseInt(selectedExpectedLeavingDate[1]),
                  };
                  setExpectedLeavingDate(newExpectedLeavingDate);
                } else {
                  setExpectedLeavingDate({
                    term: '',
                    year: '',
                  });
                }
              }}>
              <MenuItem value="">
                <em>
                  Please select {hasLeftSchool ? 'the leaving date' : 'an expected leaving date'}
                </em>
              </MenuItem>
              {suggestedExpectedLeavingDates.map((item) => {
                return (
                  <MenuItem
                    data-testid={`${item.term} ${item.year}`}
                    value={`${item.term} ${item.year}`}
                    key={`${item.term} ${item.year}`}>
                    {`${item.term}, ${item.year}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          <CircularProgress sx={{alignSelf: 'center'}} />
        )}
      </div>
    </>
  );
};

export default ExpectedLeavingDatePage;
