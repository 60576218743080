import React from 'react';
import {FormGroup, TextField} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useAuth} from '../Auth/AuthProvider';
import ProAccountFieldEdit from './ProAccountFieldEdit';

const ProAccountJobTitle = () => {
  const {authState, updateProfessionalProfile} = useAuth();
  const onSubmit = (data) => updateProfessionalProfile({...authState.user.profile, ...data});
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();
  return (
    <ProAccountFieldEdit handleSubmit={handleSubmit} onSubmit={onSubmit} buttonDisabled={false}>
      <FormGroup>
        <TextField
          label="Job Title"
          error={!!errors.job_title}
          helperText={errors.job_title && 'Please enter your job title.'}
          {...register('job_title', {required: true, value: authState.user.profile.job_title})}
        />
      </FormGroup>
    </ProAccountFieldEdit>
  );
};

export default ProAccountJobTitle;
