import React from 'react';
import {Card, CardActions, CardContent, CardHeader, Button} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ProItemCard = ({item, onClick}) => {
  return (
    <Card sx={{display: 'flex', flexDirection: 'column', height: '100%'}} elevation={4}>
      <CardHeader
        avatar={<img src={item.icon} width={42} height={42} alt="" />}
        title={item.name}
      />
      <CardContent sx={{flex: 1}} />
      <CardActions>
        <Button
          size="small"
          endIcon={<ArrowForwardIcon />}
          onClick={onClick}
          data-testid={item.name}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProItemCard;
