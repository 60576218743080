import React, {useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Box, Button, Container} from '@mui/material';

import AccountUpdatedDialog from './AccountUpdatedDialog';

const EditComponentWithSaveButton = ({
  children,
  buttonDisabled,
  onClickSave,
  dialogMessage = '',
}) => {
  const [shouldShowUpdateDialog, setShouldShowUpdateDialog] = useState(false);
  const [updateWasSuccessful, setUpdateWasSuccessful] = useState(undefined);
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <>
      <AccountUpdatedDialog
        isVisible={shouldShowUpdateDialog}
        closeDialog={() => setShouldShowUpdateDialog(false)}
        updateWasSuccessful={updateWasSuccessful}
        dialogMessage={dialogMessage}
      />
      <Container maxWidth="md">
        <Box sx={{py: 4}}>
          {isUpdating ? (
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <CircularProgress size={100} color="secondary" />
            </Box>
          ) : (
            children
          )}
        </Box>
        <Button
          color="secondary"
          variant="contained"
          sx={{float: 'right'}}
          disabled={buttonDisabled || isUpdating}
          onClick={async () => {
            setIsUpdating(true);
            const response = await onClickSave();
            setShouldShowUpdateDialog(true);
            if (response && response.status === 200) {
              setUpdateWasSuccessful(true);
            } else {
              setUpdateWasSuccessful(false);
            }
            setIsUpdating(false);
          }}>
          Save
        </Button>
      </Container>
    </>
  );
};

export default EditComponentWithSaveButton;
