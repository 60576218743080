import React, {useState} from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Chip,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {formatDateAsTerm} from '../Util/helpers';
import timelineArrowPrimary from '../img/timeline-arrow-primary.svg';
import timelineArrowSecondary from '../img/timeline-arrow-secondary.svg';
import {TransitionTimelineStageSummaryDialog} from './TransitionTimelineStageSummaryDialog';

const TimelineStage = ({
  stage,
  isHighlighted,
  handleClickOpen,
  highlightedStage,
  highlightText,
  isLast,
}) => (
  <Box>
    <Card
      sx={{
        borderRadius: 4,
        borderStyle: 'solid',
        borderColor: isHighlighted ? 'secondary.pale' : 'primary.pale',
      }}>
      <CardActionArea onClick={() => handleClickOpen(stage)}>
        <CardContent sx={{p: 2, pt: {md: 3}}}>
          <Typography variant="h3" gutterBottom>
            {stage.name}
          </Typography>
          <Typography paragraph variant="body2">
            {stage.description}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              minHeight: '32px',
            }}>
            Learn More
            <ArrowForwardIcon sx={{ml: 1}} />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
    <Box sx={{mt: -2}}>
      {highlightedStage && (
        <Chip
          label={
            <Typography variant="body2">
              {isHighlighted
                ? highlightText
                : stage.id < highlightedStage.id
                ? 'Complete'
                : formatDateAsTerm(new Date(stage.stage_start_date))}
            </Typography>
          }
          color={isHighlighted ? 'secondary' : 'primary'}
        />
      )}
      {!isLast && (
        <img
          src={isHighlighted ? timelineArrowSecondary : timelineArrowPrimary}
          height={44}
          width="100%"
        />
      )}
    </Box>
  </Box>
);

const TransitionTimeline = ({stageDates, highlightedStage, highlightText, showGlossaryLinks}) => {
  const [open, setOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState(undefined);
  const handleClickOpen = (stage) => {
    setSelectedStage(stage);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedStage(undefined);
  };
  return (
    <Box sx={{m: 2}} className="centre-aligned">
      {stageDates ? (
        stageDates.map((stage, index) => (
          <TimelineStage
            key={`stage-${index}`}
            stage={stage}
            isHighlighted={stage.id === highlightedStage?.id}
            handleClickOpen={handleClickOpen}
            highlightedStage={highlightedStage}
            highlightText={highlightText}
            isLast={index === stageDates.length - 1}
          />
        ))
      ) : (
        <CircularProgress sx={{alignSelf: 'center'}} />
      )}
      <TransitionTimelineStageSummaryDialog
        open={open}
        handleClose={handleClose}
        selectedStage={selectedStage}
        showGlossaryLinks={showGlossaryLinks}
      />
    </Box>
  );
};

export default TransitionTimeline;
