import React from 'react';
import {Box, TextField, Typography} from '@mui/material';

const DateInputPage = ({title, subtitle, month, setMonth, year, setYear, minYear, maxYear}) => {
  let monthInt = parseInt(month);
  let yearInt = parseInt(year);
  const isInvalidMonth = Number.isInteger(monthInt) && (monthInt > 12 || monthInt < 1);
  const isInvalidYear = Number.isInteger(yearInt) && (yearInt < minYear || yearInt > maxYear);
  const errors = {
    month: isInvalidMonth ? 'Month must be between 1 and 12' : undefined,
    year: isInvalidYear ? `Year must be after ${minYear} and before ${maxYear}` : undefined,
  };
  const inputProps = {
    pattern: '[0-9]*',
  };
  return (
    <Box sx={{mb: 8}}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" paragraph>
        {subtitle}
      </Typography>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <TextField
          error={!!errors.month}
          helperText={errors.month}
          className="onboarding-input date-input"
          name="month"
          value={month}
          type="number"
          onChange={(event) => setMonth(event.target.value)}
          autoFocus={true}
          placeholder="mm"
          sx={{mx: 1}}
          inputProps={{...inputProps, min: 1, max: 12}}
        />
        <TextField
          error={!!errors.year}
          helperText={errors.year}
          className="onboarding-input date-input"
          value={year}
          name="year"
          type="number"
          onChange={(event) => setYear(event.target.value)}
          placeholder="yyyy"
          sx={{mx: 1}}
          inputProps={{...inputProps, min: minYear, max: maxYear}}
        />
      </div>
    </Box>
  );
};

export default DateInputPage;
