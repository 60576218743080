import React from 'react';

import {Typography, Paper} from '@mui/material';

import {PARENT_FEEDBACK_RATING_SCALE} from '../../Util/constants';
import ResponsiveSlider from '../../Util/ResponsiveSlider';

const ProPreviousEvaluationQuestionResponse = ({response}) => {
  const RESPONSE_RATING_MAP = PARENT_FEEDBACK_RATING_SCALE.find(
    (ratingValue) => ratingValue.value === response.response_rating
  );
  const {label: responseKey, value: responseValue} = RESPONSE_RATING_MAP;
  const responseString = `${responseValue} - ${responseKey}`;

  const getAriaValueText = (value) =>
    PARENT_FEEDBACK_RATING_SCALE.find((item) => item.value === value).label;

  return (
    <Paper sx={{padding: 4, my: 2}}>
      <Typography sx={{fontStyle: 'italic'}} gutterBottom>
        {response.evaluation_question.title}
      </Typography>
      <Typography paragraph={true}>
        You selected{' '}
        <span role="text" aria-label={responseString} className="bold">
          {responseString}
        </span>
      </Typography>
      <ResponsiveSlider
        getAriaValueText={getAriaValueText}
        disabled={true}
        sx={{
          '& .MuiSlider-mark': {width: '8px', height: '8px', borderRadius: '50%'},
          '& .MuiSlider-markActive': {opacity: 1, backgroundColor: 'currentColor'},
        }}
        value={responseValue}
        marks={PARENT_FEEDBACK_RATING_SCALE}
        max={PARENT_FEEDBACK_RATING_SCALE.length}
        min={1}
        step={1}
      />
    </Paper>
  );
};

export default ProPreviousEvaluationQuestionResponse;
