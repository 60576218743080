import React, {useState, useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import {useOnboarding} from '../../Onboarding/OnboardingProvider';

const SchoolYearPage = ({month, year, schoolYear, setSchoolYear}) => {
  const {onboardingState, calculateSchoolYearFormChoices} = useOnboarding();
  const [schoolYearFormChoices, setSchoolYearFormChoices] = useState(undefined);

  useEffect(() => {
    const fetchChoices = async () => {
      if (month !== '' && year !== '') {
        setSchoolYearFormChoices(await calculateSchoolYearFormChoices(month, year));
      }
    };
    fetchChoices();
  }, [month, year]);

  return (
    <>
      <h1 className="header-4 auth-text">What school year is your young person in?</h1>
      <div className="body2 auth-text-sm">
        We use this to calculate your stage in the transitions path.
      </div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {schoolYearFormChoices !== undefined ? (
          <RadioGroup
            aria-label="school-year"
            name="school-year"
            value={parseInt(schoolYear)}
            onChange={(event) => {
              setSchoolYear(event.target.value);
            }}>
            {schoolYearFormChoices.map((item) => (
              <FormControlLabel
                key={item}
                label={item}
                value={onboardingState.schoolYears.indexOf(item)}
                control={<Radio color="secondary" />}
              />
            ))}
          </RadioGroup>
        ) : (
          <CircularProgress sx={{alignSelf: 'center'}} />
        )}
      </div>
    </>
  );
};

export default SchoolYearPage;
