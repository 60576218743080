import React, {useState} from 'react';
import {
  FormGroup,
  FormLabel,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  CircularProgress,
} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

import {useAuth} from '../Auth/AuthProvider';
import ProAccountFieldEdit from './ProAccountFieldEdit';
import useHealthBoardAreas from '../Queries/useHealthBoardAreas';
import {PRO_REGION_TYPES} from '../Util/constants';

const ProAccountArea = () => {
  const {authState, updateProfessionalProfile} = useAuth();
  const profile = authState.user.profile;
  const [selectedRegionType, setSelectedRegionType] = useState(
    profile.local_authority_id
      ? 'Local Authority'
      : profile.health_board_area_id
      ? 'Health Board'
      : profile.national_scotland
      ? 'National (Scotland)'
      : 'Outside Scotland'
  );
  const onSubmit = async (data) => {
    // Based on the selectedRegionType, we may need to null other fields
    const update = {
      local_authority_id: selectedRegionType === 'Local Authority' ? data.local_authority_id : null,
      health_board_area_id:
        selectedRegionType === 'Health Board' ? data.health_board_area_id : null,
      national_scotland: selectedRegionType === 'National (Scotland)' ? true : false,
    };
    return await updateProfessionalProfile({...authState.user.profile, ...update});
  };
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const healthBoardAreasQuery = useHealthBoardAreas();
  const {data: localAuthorities, isLoading} = useQuery({
    queryKey: ['localAuthorities'],
    queryFn: () => axios.get('/api/local-authorities').then((res) => res.data),
    staleTime: Infinity,
  });
  return isLoading ? (
    <div>Loading</div>
  ) : (
    <ProAccountFieldEdit handleSubmit={handleSubmit} onSubmit={onSubmit} buttonDisabled={false}>
      <FormGroup>
        <FormLabel id="region-radio-buttons-group-label">
          Please select the type of region that best reflects where you work:
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="region-radio-buttons-group"
          name="region-radio-buttons-group"
          value={selectedRegionType}
          onChange={(event) => setSelectedRegionType(event.target.value)}
          sx={{mb: 3}}>
          {PRO_REGION_TYPES.map((regionType) => (
            <FormControlLabel
              key={`region-type-${regionType}`}
              value={regionType}
              control={<Radio />}
              label={regionType}
            />
          ))}
        </RadioGroup>
      </FormGroup>
      {selectedRegionType === 'Local Authority' && (
        <FormGroup>
          <TextField
            label="Local Authority"
            defaultValue={profile.local_authority_id ?? ''}
            select
            sx={{textAlign: 'left'}}
            error={!!errors.local_authority_id}
            helperText={errors.local_authority_id && 'Please select your local authority.'}
            {...register('local_authority_id', {required: true})}>
            {localAuthorities.map((localAuthority) => (
              <MenuItem key={`local-authority-${localAuthority.id}`} value={localAuthority.id}>
                {localAuthority.name}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
      )}
      {selectedRegionType === 'Health Board' &&
        (!healthBoardAreasQuery.isLoading ? (
          <FormGroup>
            <TextField
              label="Health Board Area"
              defaultValue={profile.health_board_area_id ?? ''}
              select
              sx={{textAlign: 'left'}}
              error={!!errors.health_board_area_id}
              helperText={errors.health_board_area_id && 'Please select your health board area.'}
              {...register('health_board_area_id', {required: true})}>
              {healthBoardAreasQuery.data.map((healthBoardArea) => (
                <MenuItem
                  key={`health-board-area-${healthBoardArea.id}`}
                  value={healthBoardArea.id}>
                  {healthBoardArea.name}
                </MenuItem>
              ))}
            </TextField>
          </FormGroup>
        ) : (
          <CircularProgress />
        ))}
    </ProAccountFieldEdit>
  );
};

export default ProAccountArea;
