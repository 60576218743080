import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Cookies} from 'react-cookie-consent';
import {useMatomo} from '@jonkoops/matomo-tracker-react';
import {Switch, Typography, Link, Divider, ListItemButton} from '@mui/material';

const MyAccountCookieToggle = () => {
  const {pushInstruction} = useMatomo();
  const [cookieConsent, setCookieConsent] = useState(
    Cookies.get('compassCookieConsent') === 'true' // Cookies.get returns a string, make it a boolean
  );
  const onToggle = () => {
    if (cookieConsent) {
      Cookies.set('compassCookieConsent', 'false');
      pushInstruction('forgetCookieConsentGiven');
      setCookieConsent(false);
    } else {
      Cookies.set('compassCookieConsent', 'true');
      pushInstruction('setCookieConsentGiven');
      pushInstruction('rememberCookieConsentGiven');
      setCookieConsent(true);
    }
  };
  return (
    <>
      <Typography variant="h3" sx={{mt: 2}} gutterBottom>
        Analytics cookies
      </Typography>
      <Typography variant="body2" paragraph>
        Compass uses cookies to collect analytics data to improve your experience. You can change
        your cookie settings at any time. Read our{' '}
        <Link component={RouterLink} to="/privacy" target="_blank" rel="noreferrer">
          privacy policy
        </Link>{' '}
        to find out more.
      </Typography>
      <ListItemButton divider onClick={onToggle}>
        <Typography sx={{flex: 1}}>Accept analytics cookies</Typography>
        <Switch
          data-testid="cookie-consent-switch"
          checked={cookieConsent}
          onChange={onToggle}
          inputProps={{'aria-label': 'Cookie consent switch'}}
        />
      </ListItemButton>
      <Divider aria-hidden="true" />
    </>
  );
};

export default MyAccountCookieToggle;
