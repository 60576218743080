import React, {useState} from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import EditComponentWithSaveButton from './EditComponentWithSaveButton';
import {useAuth} from '../Auth/AuthProvider';
import {Typography} from '@mui/material';

const notificationChannels = ['Email', 'SMS'];

const NotificationPreferences = () => {
  const {authState, updateUser} = useAuth();
  const {user} = authState;
  // Each notification setting is stored in a column, but they are inherently hierarchical (type and channel)
  // This useState has the same shape as the notification columns in the User model, making updates simple
  // But avoids hardcoding the state and the render function in React
  const [userNotificationState, setUserNotificationState] = useState(
    Object.keys(user.notification_structure.user).reduce(
      (typeAccumulator, columnPrefix) => ({
        ...typeAccumulator,
        ...notificationChannels.reduce(
          (channelAccumulator, channelName) => ({
            ...channelAccumulator,
            [`${columnPrefix}_notification_${channelName.toLowerCase()}`]:
              user[`${columnPrefix}_notification_${channelName.toLowerCase()}`],
          }),
          {}
        ),
      }),
      {
        email_notifications: user.email_notifications,
        sms_notifications: user.sms_notifications,
      }
    )
  );

  // Local copy of notification settings on the Profile model (different for each user type)
  // const [profileNotificationState, setProfileNotificationState] = useState(
  //   user.isParent
  //     ? {
  //         // values from ParentProfile
  //       }
  //     : {
  //         // values from YPProfile
  //       }
  // );

  const handleSave = async () => {
    return await updateUser(userNotificationState);
    // if (user.isParent) {
    //   // await updateParentProfile(profileNotificationState);
    // } else {
    //   await updateYoungPersonProfile(profileNotificationState);
    // }
    // check both responses, if they both succeeded return either of them
    // if one failed, return the failed response
  };

  return (
    <EditComponentWithSaveButton onClickSave={handleSave}>
      <Typography variant="h2" gutterBottom>
        How would you like to be contacted?
      </Typography>
      <Typography paragraph>Adjust your notification settings here</Typography>
      <TableContainer component={Paper} sx={{mt: 2}}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {notificationChannels.map((channelName) => {
                const channelColumn = `${channelName.toLowerCase()}_notifications`;
                return (
                  <TableCell key={channelName}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!userNotificationState[channelColumn]}
                          onChange={(event) =>
                            setUserNotificationState({
                              ...userNotificationState,
                              [channelColumn]: event.target.checked,
                            })
                          }
                          value={channelColumn}
                          color="secondary"
                        />
                      }
                      label={`All ${channelName} notifications`}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(user.notification_structure.user).map(([columnPrefix, label]) => (
              <TableRow key={columnPrefix}>
                <TableCell component="th" scope="row">
                  {label}
                </TableCell>
                {notificationChannels.map((channelName) => {
                  const channelColumn = `${channelName.toLowerCase()}_notifications`;
                  const channelNotificationColumn = `${columnPrefix}_notification_${channelName.toLowerCase()}`;
                  return (
                    <TableCell key={channelName}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !!userNotificationState[channelColumn] &&
                              !!userNotificationState[channelNotificationColumn]
                            }
                            disabled={!userNotificationState[channelColumn]}
                            onChange={(event) =>
                              setUserNotificationState({
                                ...userNotificationState,
                                [channelNotificationColumn]: event.target.checked,
                              })
                            }
                            name={channelNotificationColumn}
                            color="secondary"
                          />
                        }
                        label={channelName}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </EditComponentWithSaveButton>
  );
};

export default NotificationPreferences;
