import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';

import MyAccountDetails from '../Account/MyAccountDetails';
import {useAuth} from '../Auth/AuthProvider';
import PageHeader from '../Util/PageHeader';
import AccountHeaderIcon from '../img/account-header.svg';
import AccountItem from '../Account/AccountItem';
import MyAccountCookieToggle from '../Util/MyAccountCookieToggle';
import {Box, Container, Typography} from '@mui/material';

const ProAccount = () => {
  const {authState} = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;
  const hasLoaded = authState.user.id !== undefined;
  const profile = authState.user.profile;
  return hasLoaded ? (
    <>
      <PageHeader
        color={dark}
        name={'My Account'}
        icon={AccountHeaderIcon}
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{py: 6}}>
        <Typography paragraph>
          Here is all the personal information you shared with Compass when you signed up. You can
          change this at any time.
        </Typography>
        <Box sx={{mb: 3}}>
          <Typography variant="h3" component="h2" gutterBottom>
            My Information
          </Typography>
          <MyAccountDetails user={authState.user} />
        </Box>
        {profile ? (
          <Box sx={{mb: 3}}>
            <Typography variant="h3" component="h2" gutterBottom>
              My Professional Profile
            </Typography>
            <AccountItem heading="Job Title:" onClick={() => navigate('edit/job-title')}>
              <Typography variant="body2">{profile.job_title}</Typography>
            </AccountItem>
            <AccountItem heading="Sector:" onClick={() => navigate('edit/sector')}>
              <Typography variant="body2">{profile.sector.name}</Typography>
            </AccountItem>
            <AccountItem heading="Area:" onClick={() => navigate('edit/area')}>
              <Typography variant="body2">
                {profile.local_authority_id
                  ? profile.local_authority.name
                  : profile.health_board_area_id
                  ? profile.health_board_area.name
                  : profile.national_scotland
                  ? 'National (Scotland)'
                  : 'Outside Scotland'}
              </Typography>
            </AccountItem>
          </Box>
        ) : (
          <div>Loading</div>
        )}
        <MyAccountCookieToggle />
      </Container>
    </>
  ) : null;
};

export default ProAccount;
