import {Button, DialogActions, DialogContent, DialogTitle, Paper} from '@mui/material';
import React from 'react';
import Joyride, {EVENTS} from 'react-joyride';

import {JOYRIDE_DEFAULT_PROPS} from './constants';

// Based on https://github.com/gilbarbara/react-joyride/blob/main/src/components/Tooltip/Container.js
const TourToolTip = ({
  continuous,
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep = false,
}) => {
  const {title, content, hideBackButton, locale, showProgress, styles} = step;
  const {back, close, last, next} = locale;
  // Use some of the style propped in, but mostly ignore it (a lot of it is typography style)
  const tooltipStyle = {width: styles.tooltip.width, maxWidth: '100%', position: 'relative'};
  return (
    // We use Paper instead of Dialog to avoid battling Mui's dialog positioning
    <Paper {...tooltipProps} style={tooltipStyle}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent sx={{py: 0}}>{content}</DialogContent>
      <DialogActions>
        {!hideBackButton && index > 0 && (
          <Button variant="outlined" {...backProps}>
            {back}
          </Button>
        )}
        {continuous ? (
          <Button color="secondary" variant="contained" {...primaryProps}>
            <span>
              {isLastStep ? last : next}
              {showProgress && ` (${index + 1}/${size})`}
            </span>
          </Button>
        ) : (
          <Button color="secondary" {...closeProps}>
            {close}
          </Button>
        )}
      </DialogActions>
    </Paper>
  );
};

const HomeScreenTour = ({run, setRun, steps, onComplete = () => {}, hideProgress = false}) => {
  return (
    <Joyride
      {...JOYRIDE_DEFAULT_PROPS}
      run={run}
      steps={steps}
      tooltipComponent={TourToolTip}
      showProgress={!hideProgress}
      callback={async ({type}) => {
        if (type === EVENTS.TOUR_END) {
          // setRun(false) so we can replay the tour
          setRun(false);
          await onComplete();
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
      }}
    />
  );
};

export default HomeScreenTour;
