import transitionIcon1 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-1.svg';
import transitionIcon2 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-2.svg';
import transitionIcon3 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-3.svg';
import transitionIcon4 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-4.svg';
import transitionIcon5 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-5.svg';
import transitionIcon6 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-6.svg';
import transitionIcon7 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-7.svg';
import transitionIcon8 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-8.svg';
import transitionIcon9 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-9.svg';
import transitionIcon10 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-10.svg';
import transitionIcon11 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-11.svg';
import transitionIcon12 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-12.svg';
import transitionIcon13 from '../img/YoungPersonTopicIcons/leading-my-own-life/transition-topic-13.svg';

import transitionIcon14 from '../img/YoungPersonTopicIcons/making-safe-choices/transition-topic-14.svg';
import transitionIcon15 from '../img/YoungPersonTopicIcons/making-safe-choices/transition-topic-15.svg';
import transitionIcon16 from '../img/YoungPersonTopicIcons/making-safe-choices/transition-topic-16.svg';
import transitionIcon17 from '../img/YoungPersonTopicIcons/making-safe-choices/transition-topic-17.svg';
import transitionIcon18 from '../img/YoungPersonTopicIcons/making-safe-choices/transition-topic-18.svg';
import transitionIcon19 from '../img/YoungPersonTopicIcons/making-safe-choices/transition-topic-19.svg';
import transitionIcon20 from '../img/YoungPersonTopicIcons/making-safe-choices/transition-topic-20.svg';
import transitionIcon21 from '../img/YoungPersonTopicIcons/making-safe-choices/transition-topic-21.svg';

import transitionIcon22 from '../img/YoungPersonTopicIcons/finding-and-getting-a-job/transition-topic-22.svg';
import transitionIcon23 from '../img/YoungPersonTopicIcons/finding-and-getting-a-job/transition-topic-23.svg';
import transitionIcon24 from '../img/YoungPersonTopicIcons/finding-and-getting-a-job/transition-topic-24.svg';
import transitionIcon25 from '../img/YoungPersonTopicIcons/finding-and-getting-a-job/transition-topic-25.svg';
import transitionIcon26 from '../img/YoungPersonTopicIcons/finding-and-getting-a-job/transition-topic-26.svg';
import transitionIcon27 from '../img/YoungPersonTopicIcons/finding-and-getting-a-job/transition-topic-27.svg';

import transitionIcon28 from '../img/YoungPersonTopicIcons/my-health-and-care/transition-topic-28.svg';
import transitionIcon29 from '../img/YoungPersonTopicIcons/my-health-and-care/transition-topic-29.svg';
import transitionIcon30 from '../img/YoungPersonTopicIcons/my-health-and-care/transition-topic-30.svg';
import transitionIcon31 from '../img/YoungPersonTopicIcons/my-health-and-care/transition-topic-31.svg';
import transitionIcon32 from '../img/YoungPersonTopicIcons/my-health-and-care/transition-topic-32.svg';
import transitionIcon33 from '../img/YoungPersonTopicIcons/my-health-and-care/transition-topic-33.svg';
import transitionIcon34 from '../img/YoungPersonTopicIcons/my-health-and-care/transition-topic-34.svg';
import transitionIcon35 from '../img/YoungPersonTopicIcons/my-health-and-care/transition-topic-35.svg';

import transitionIcon36 from '../img/YoungPersonTopicIcons/friends-and-relationships/transition-topic-36.svg';
import transitionIcon37 from '../img/YoungPersonTopicIcons/friends-and-relationships/transition-topic-37.svg';
import transitionIcon38 from '../img/YoungPersonTopicIcons/friends-and-relationships/transition-topic-38.svg';
import transitionIcon39 from '../img/YoungPersonTopicIcons/friends-and-relationships/transition-topic-39.svg';
import transitionIcon40 from '../img/YoungPersonTopicIcons/friends-and-relationships/transition-topic-40.svg';
import transitionIcon41 from '../img/YoungPersonTopicIcons/friends-and-relationships/transition-topic-41.svg';
import transitionIcon42 from '../img/YoungPersonTopicIcons/friends-and-relationships/transition-topic-42.svg';
import transitionIcon43 from '../img/YoungPersonTopicIcons/friends-and-relationships/transition-topic-43.svg';
import transitionIcon44 from '../img/YoungPersonTopicIcons/friends-and-relationships/transition-topic-44.svg';

import transitionIcon45 from '../img/YoungPersonTopicIcons/big-changes/transition-topic-45.svg';
import transitionIcon46 from '../img/YoungPersonTopicIcons/big-changes/transition-topic-46.svg';
import transitionIcon47 from '../img/YoungPersonTopicIcons/big-changes/transition-topic-47.svg';
import transitionIcon48 from '../img/YoungPersonTopicIcons/big-changes/transition-topic-48.svg';
import transitionIcon49 from '../img/YoungPersonTopicIcons/big-changes/transition-topic-49.svg';
import transitionIcon50 from '../img/YoungPersonTopicIcons/big-changes/transition-topic-50.svg';
import transitionIcon51 from '../img/YoungPersonTopicIcons/big-changes/transition-topic-51.svg';

import transitionIcon52 from '../img/YoungPersonTopicIcons/sharing-what-i-think/transition-topic-52.svg';
import transitionIcon53 from '../img/YoungPersonTopicIcons/sharing-what-i-think/transition-topic-53.svg';
import transitionIcon54 from '../img/YoungPersonTopicIcons/sharing-what-i-think/transition-topic-54.svg';
import transitionIcon55 from '../img/YoungPersonTopicIcons/sharing-what-i-think/transition-topic-55.svg';
import transitionIcon56 from '../img/YoungPersonTopicIcons/sharing-what-i-think/transition-topic-56.svg';

import transitionIcon57 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-57.svg';
import transitionIcon58 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-58.svg';
import transitionIcon59 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-59.svg';
import transitionIcon60 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-60.svg';
import transitionIcon61 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-61.svg';
import transitionIcon62 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-62.svg';
import transitionIcon63 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-63.svg';
import transitionIcon64 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-64.svg';
import transitionIcon65 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-65.svg';
import transitionIcon66 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-66.svg';
import transitionIcon67 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-67.svg';
import transitionIcon68 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-68.svg';
import transitionIcon69 from '../img/YoungPersonTopicIcons/being-my-best-self/transition-topic-69.svg';

export const YOUNG_PERSON_CATEGORY_TOPIC_ICONS = {
  'transition-topic-1': transitionIcon1,
  'transition-topic-2': transitionIcon2,
  'transition-topic-3': transitionIcon3,
  'transition-topic-4': transitionIcon4,
  'transition-topic-5': transitionIcon5,
  'transition-topic-6': transitionIcon6,
  'transition-topic-7': transitionIcon7,
  'transition-topic-8': transitionIcon8,
  'transition-topic-9': transitionIcon9,
  'transition-topic-10': transitionIcon10,
  'transition-topic-11': transitionIcon11,
  'transition-topic-12': transitionIcon12,
  'transition-topic-13': transitionIcon13,

  'transition-topic-14': transitionIcon14,
  'transition-topic-15': transitionIcon15,
  'transition-topic-16': transitionIcon16,
  'transition-topic-17': transitionIcon17,
  'transition-topic-18': transitionIcon18,
  'transition-topic-19': transitionIcon19,
  'transition-topic-20': transitionIcon20,
  'transition-topic-21': transitionIcon21,

  'transition-topic-22': transitionIcon22,
  'transition-topic-23': transitionIcon23,
  'transition-topic-24': transitionIcon24,
  'transition-topic-25': transitionIcon25,
  'transition-topic-26': transitionIcon26,
  'transition-topic-27': transitionIcon27,

  'transition-topic-28': transitionIcon28,
  'transition-topic-29': transitionIcon29,
  'transition-topic-30': transitionIcon30,
  'transition-topic-31': transitionIcon31,
  'transition-topic-32': transitionIcon32,
  'transition-topic-33': transitionIcon33,
  'transition-topic-34': transitionIcon34,
  'transition-topic-35': transitionIcon35,

  'transition-topic-36': transitionIcon36,
  'transition-topic-37': transitionIcon37,
  'transition-topic-38': transitionIcon38,
  'transition-topic-39': transitionIcon39,
  'transition-topic-40': transitionIcon40,
  'transition-topic-41': transitionIcon41,
  'transition-topic-42': transitionIcon42,
  'transition-topic-43': transitionIcon43,
  'transition-topic-44': transitionIcon44,

  'transition-topic-45': transitionIcon45,
  'transition-topic-46': transitionIcon46,
  'transition-topic-47': transitionIcon47,
  'transition-topic-48': transitionIcon48,
  'transition-topic-49': transitionIcon49,
  'transition-topic-50': transitionIcon50,
  'transition-topic-51': transitionIcon51,

  'transition-topic-52': transitionIcon52,
  'transition-topic-53': transitionIcon53,
  'transition-topic-54': transitionIcon54,
  'transition-topic-55': transitionIcon55,
  'transition-topic-56': transitionIcon56,

  'transition-topic-57': transitionIcon57,
  'transition-topic-58': transitionIcon58,
  'transition-topic-59': transitionIcon59,
  'transition-topic-60': transitionIcon60,
  'transition-topic-61': transitionIcon61,
  'transition-topic-62': transitionIcon62,
  'transition-topic-63': transitionIcon63,
  'transition-topic-64': transitionIcon64,
  'transition-topic-65': transitionIcon65,
  'transition-topic-66': transitionIcon66,
  'transition-topic-67': transitionIcon67,
  'transition-topic-68': transitionIcon68,
  'transition-topic-69': transitionIcon69,
};
