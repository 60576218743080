import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import {Container, Link, TextField, Typography, useTheme} from '@mui/material';
import {Box} from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';

import glossaryGraphic from '../img/glossary-graphic.svg';
const {glossaryIcon} = sectionIcons;
import {sectionIcons, NUM_GLOSSARY_LINKS, DIALOG_URL_PARAMS} from '../Util/constants';
import DialogToolbarBackButton from '../Util/DialogToolbarBackButton';

import useGlossaryTerms from '../Queries/useGlossaryTerms';
import useDialogQuery from '../Util/useDialogQuery';
import {onCloseUnlessBackdropClick} from '../Util/helpers';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GlossaryDialog = () => {
  const [currentSearch, setCurrentSearch] = useState('');
  const {data: glossaryTerms, isLoading, error} = useGlossaryTerms();
  const {closeDialog} = useDialogQuery();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('term') ?? '';

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error</div>;
  }

  let glossaryItemsByFirstLetter = glossaryTerms
    .filter(
      currentSearch === ''
        ? () => true
        : ({term}) => term.toLowerCase().includes(currentSearch.toLowerCase())
    )
    .reduce((acc, term) => {
      // create a key on each term, 'links', that is an array of objects with title and url keys
      let newTerm = {};
      let glossaryLinks = [];
      for (let i = 0; i < NUM_GLOSSARY_LINKS; i++) {
        const linkNumber = i + 1;
        const link = term[`link_${linkNumber}`];
        const title = term[`link_${linkNumber}_title`];
        if (link) {
          glossaryLinks.push({link, title});
        }
      }
      newTerm = {...term, links: glossaryLinks};
      // add the term to the array of terms that start with the same letter
      const firstLetter = term.term[0].toUpperCase();
      if (acc[firstLetter]) {
        acc[firstLetter].push(newTerm);
      } else {
        acc[firstLetter] = [newTerm];
      }
      return acc;
    }, {});

  const lettersWithTerms = Object.keys(glossaryItemsByFirstLetter).sort();

  // glossaryItemsByFirstLetter is an object with keys of letters and values of arrays of terms
  // iterate through glossaryItemsByFirstLetter to find the item matching searchTerm
  // use searchTerm[0] to get the first letter of the search term and use that to search through the right key
  const matchingTerm =
    glossaryItemsByFirstLetter[searchTerm[0]]?.find((term) => term.term === searchTerm) ??
    undefined;

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {backgroundColor: theme.palette.background.default},
      }}>
      <AppBar color="glossary" position="static">
        <Toolbar sx={{justifyContent: 'space-between'}}>
          {matchingTerm && (
            <DialogToolbarBackButton
              onBackClick={() => {
                setSearchParams((params) => {
                  params.delete(DIALOG_URL_PARAMS.GLOSSARY_TERM);
                  return params;
                });
              }}
            />
          )}
          <Typography sx={{ml: 'auto'}} variant="h4" component="h1">
            Transitions A-Z
          </Typography>
          <img
            src={glossaryIcon}
            height={40}
            style={{marginLeft: 12, marginRight: 'auto'}}
            aria-hidden="true"
            alt=""
          />
          <IconButton
            edge="start"
            onClick={() => {
              setCurrentSearch('');
              closeDialog();
            }}
            aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{py: 6}}>
        {lettersWithTerms === undefined ? (
          'Loading'
        ) : !matchingTerm ? (
          <>
            <Box
              component={'img'}
              src={glossaryGraphic}
              sx={{my: 2, mx: 'auto', display: 'block'}}
            />
            <Box sx={{p: 1}}>
              <TextField
                placeholder="Search for something"
                fullWidth
                value={currentSearch}
                onChange={(event) => setCurrentSearch(event.target.value)}
                inputProps={{
                  'data-testid': 'glossary-search-input',
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{visibility: currentSearch === '' ? 'hidden' : 'visible'}}
                      onClick={() => setCurrentSearch('')}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <List>
              {lettersWithTerms.map((letter) => (
                <div key={letter}>
                  <ListSubheader disableSticky>{letter}</ListSubheader>
                  {glossaryItemsByFirstLetter[letter].map((glossaryTerm, index) => (
                    <ListItem
                      button
                      key={glossaryTerm.id}
                      onClick={() => {
                        const newSearchParams = searchParams;
                        newSearchParams.set(DIALOG_URL_PARAMS.GLOSSARY_TERM, glossaryTerm.term);
                        setSearchParams(newSearchParams);
                      }}>
                      <ListItemText primary={glossaryTerm.term} />
                      {index < glossaryItemsByFirstLetter[letter].length - 1 && (
                        <Divider aria-hidden="true" />
                      )}
                    </ListItem>
                  ))}
                </div>
              ))}
            </List>
          </>
        ) : (
          <>
            <Typography variant="h2" gutterBottom>
              {matchingTerm.term}
            </Typography>
            <Divider aria-hidden="true" sx={{mb: 2}} />
            <Typography paragraph sx={{mb: 4}}>
              {matchingTerm.description}
            </Typography>
            {matchingTerm.links && matchingTerm.links.length > 0 && (
              <>
                <Typography variant="h3" gutterBottom>
                  Helpful Reading
                </Typography>
                <Divider aria-hidden="true" sx={{mb: 2}} />
                {matchingTerm.links.map(({link, title}) => (
                  <Typography paragraph key={link}>
                    <Link href={link} target="_blank" rel="noreferrer">
                      {title ? title : link}
                    </Link>
                  </Typography>
                ))}
              </>
            )}
          </>
        )}
      </Container>
    </Dialog>
  );
};

export default GlossaryDialog;
