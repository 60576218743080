import React, {createContext, useContext, useEffect, useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {useMatomo} from '@jonkoops/matomo-tracker-react';
import * as Sentry from '@sentry/react';

import {MATOMO_CUSTOM_DIMENSION_IDS} from '../MatomoAnalytics';
import LogoutConfirmationDialog from '../LogoutConfirmationDialog';
import PhoneNumberDialog from '../Account/PhoneNumberDialog';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const AuthContext = createContext();

const REGISTER_URL = '/auth/register';
const CHECK_AUTH_URL = '/api/checkAuth';
const LOGIN_URL = '/auth/login';
const LOGOUT_URL = '/auth/logout';
const COOKIE_URL = '/sanctum/csrf-cookie';
const PASSWORD_EMAIL_URL = '/auth/password/email';
const RESET_PASSWORD_URL = '/auth/password/reset';
const USER_URL = '/api/user';
const DELETE_URL = '/api/user/delete';
const FORCE_DELETE_URL = '/api/user/force-delete';
const UPDATE_USER_URL = '/api/user/update';
const UPDATE_PASSWORD_URL = '/api/user/update-password';

const initialState = {
  isCheckingAuth: true,
  isAuthenticated: undefined,
  isDeleted: false,
  user: {
    id: undefined,
    name: '',
    email: '',
    isParent: false,
    isYoungPerson: false,
    isProfessional: false,
    createdAt: undefined,
  },
};

const authReducer = (authState, action) => {
  switch (action.type) {
    case 'SET_IS_AUTHENTICATED':
      return Object.assign({}, authState, {
        isAuthenticated: action.payload,
        isCheckingAuth: false,
      });
    case 'SET_USER_DATA':
      return Object.assign({}, authState, {
        user: action.payload,
      });
    case 'SET_IS_DELETED':
      return Object.assign({}, authState, {
        isDeleted: action.payload,
      });
  }
};

function AuthProvider({children}) {
  const [authState, dispatch] = useReducer(authReducer, initialState);
  const [redirectUrlForAuth, setRedirectUrlForAuth] = useState();
  const [logoutDialogIsVisible, setLogoutDialogIsVisible] = useState(false);
  const [phoneNumberDialogIsVisible, setPhoneNumberDialogIsVisible] = useState(false);
  const navigate = useNavigate();
  const {pushInstruction} = useMatomo();

  const fetchCSRFToken = async () => await axios.get(COOKIE_URL);

  const displayRegisterErrorAlert = (error) => {
    if (error.response.data.errors.email) {
      alert(`There was an error creating your account.\n${error.response.data.errors.email[0]}`);
    } else if (error.response.data.errors.password) {
      alert(`There was an error creating your account.\n${error.response.data.errors.password[0]}`);
    } else {
      alert(`There was an error creating your account.\n${error.response.data.message}`);
    }
  };

  const register = async (formData) => {
    const {name, email, phone_number, password} = formData;
    const password_confirmation = password;
    if (name === undefined || email === undefined || password === undefined) {
      alert('Please complete the required fields.');
      return;
    }
    try {
      await axios.post(REGISTER_URL, {
        name,
        email,
        phone_number,
        password,
        password_confirmation,
      });
      login(email, password);
    } catch (error) {
      if (!error.response) {
        alert('There was an error creating your account.');
      } else {
        displayRegisterErrorAlert(error);
      }
    }
  };

  const login = async (email, password) => {
    let loginResponse;
    try {
      loginResponse = await axios.post(LOGIN_URL, {email, password});
      dispatch({type: 'SET_IS_AUTHENTICATED', payload: true});
    } catch (error) {
      dispatch({type: 'SET_IS_AUTHENTICATED', payload: false});
      alert(
        error.response?.data?.message ??
          'Login failed. Please check your credentials or try again later.'
      );
    }
    return loginResponse;
  };

  const showLogoutDialog = () => setLogoutDialogIsVisible(true);

  const logout = async () => {
    const logoutResponse = await axios.post(LOGOUT_URL);
    await checkAuthenticated();
    dispatch({type: 'SET_USER_DATA', payload: initialState.user});
    Sentry.setUser(null);
    navigate('/');
    return logoutResponse;
  };

  const requestPasswordReset = async (email) => {
    try {
      const passwordResetResponse = await axios.post(PASSWORD_EMAIL_URL, {email});
      return passwordResetResponse;
    } catch {
      alert('There was a problem resetting your password. Please try again later.');
    }
  };

  const submitPasswordReset = async (token, email, password) => {
    try {
      await axios.post(RESET_PASSWORD_URL, {
        token,
        email,
        password,
        password_confirmation: password,
      });
      dispatch({type: 'SET_IS_AUTHENTICATED', payload: true});
    } catch {
      alert(
        'There was a problem resetting your password. Please check your password meets the requirements and try again later.'
      );
    }
  };

  const updatePassword = async (password) => {
    try {
      const authenticatedPasswordResetResponse = await axios.put(UPDATE_PASSWORD_URL, password);
      dispatch({type: 'SET_IS_AUTHENTICATED', payload: true});
      return authenticatedPasswordResetResponse;
    } catch {
      alert(
        'There was a problem resetting your password. Please check your password meets the requirements and try again later.'
      );
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(USER_URL);
      const {data: user} = response;
      dispatch({type: 'SET_USER_DATA', payload: user});

      // update matomo with user data
      pushInstruction('setUserId', user.id);
      pushInstruction(
        'setCustomDimension',
        MATOMO_CUSTOM_DIMENSION_IDS.USER_PROFILE_TYPE,
        user.isParent
          ? 'parent'
          : user.isYoungPerson
          ? 'young-person'
          : user.isProfessional
          ? 'professional'
          : null
      );
      const localAuthorityName =
        (user.isParent
          ? user.young_people[0]?.school_local_authority?.name
          : user.isYoungPerson || user.isProfessional
          ? user.profile.local_authority?.name
          : null) ?? 'Unknown';

      pushInstruction(
        'setCustomDimension',
        MATOMO_CUSTOM_DIMENSION_IDS.LOCAL_AUTHORITY,
        localAuthorityName
      );

      // Tell Sentry which user we're logged in as
      Sentry.setUser({
        id: user.id,
      });
    } catch (error) {
      alert(
        error.response?.data?.message ??
          'Login failed. Please check your credentials or try again later.'
      );
      // this makes sure our 403 page is displayed if user is attempting to access wrong tool
      window.location.reload();
    }
  };

  const deleteUser = async () => {
    const user = await axios.delete(DELETE_URL);
    dispatch({type: 'SET_IS_DELETED', payload: true});
    await logout();
    return user;
  };

  const permanentlyDeleteUser = async () => {
    const user = await axios.delete(FORCE_DELETE_URL);
    dispatch({type: 'SET_IS_DELETED', payload: true});
    await logout();
    return user;
  };

  const checkAuthenticated = async () => {
    try {
      await fetchCSRFToken();
      const checkAuthResponse = await axios.get(CHECK_AUTH_URL);
      dispatch({type: 'SET_IS_AUTHENTICATED', payload: checkAuthResponse.data});
    } catch (error) {
      console.error('check authenticated error', error);
    }
  };

  const updateUser = async (user, password) => {
    // TODO: consider refactoring to return the promise/response
    // currently, other components can't fail gracefully because the response is not sent down
    // and this function doesn't throw (since it catches the error)
    try {
      const userData = {
        ...user,
        ...(password && password),
      };
      const response = await axios.put(UPDATE_USER_URL, userData);
      dispatch({type: 'SET_USER_DATA', payload: response.data});
      return response;
    } catch (error) {
      console.log('could not update user', error);
    }
  };

  const updateYoungPersonProfile = async (youngPersonProfile) => {
    const updateYoungPersonProfileResponse = await axios.put(
      `/api/young-person-profile/${youngPersonProfile.id}`,
      youngPersonProfile
    );
    await fetchUser();
    return updateYoungPersonProfileResponse;
  };

  const updateProfessionalProfile = async (professionalProfile) => {
    const updateProfessionalProfileResponse = await axios.put(
      `/api/professional-profile/${professionalProfile.id}`,
      professionalProfile
    );
    await fetchUser();
    return updateProfessionalProfileResponse;
  };

  useEffect(() => {
    checkAuthenticated();
  }, []);

  useEffect(() => {
    // runs on successful login or checkAuthenticated
    if (authState.isAuthenticated) {
      fetchUser();
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    const timer = setInterval(async () => {
      // Every hour, hit the COOKIE_URL route to renew the CSRF token
      fetchCSRFToken();
    }, 3600000);
    return () => clearInterval(timer);
  }, []);

  const {isYoungPerson, isParent, isProfessional} = authState.user;
  const hasFetchedUser = !!authState.user.id;
  const hasRatedTopics =
    authState.user.profile &&
    authState.user.profile.current_topic_ratings &&
    !!authState.user.profile.current_topic_ratings.length;

  useEffect(() => {
    if (hasFetchedUser) {
      if (authState.user.should_prompt_for_phone_number && !authState.user.should_show_intro_tour) {
        setPhoneNumberDialogIsVisible(true);
      }
    }
  }, [hasFetchedUser]);

  const value = {
    authState,
    axios,
    dispatch,
    register,
    login,
    logout,
    showLogoutDialog,
    requestPasswordReset,
    submitPasswordReset,
    fetchUser,
    deleteUser,
    permanentlyDeleteUser,
    checkAuthenticated,
    updateUser,
    updatePassword,
    updateYoungPersonProfile,
    updateProfessionalProfile,
    isYoungPerson,
    isParent,
    isProfessional,
    hasFetchedUser,
    hasRatedTopics,
    redirectUrlForAuth,
    setRedirectUrlForAuth,
  };
  return (
    <AuthContext.Provider value={value}>
      {children}
      <LogoutConfirmationDialog
        isVisible={logoutDialogIsVisible}
        closeDialog={() => setLogoutDialogIsVisible(false)}
      />
      <PhoneNumberDialog
        isVisible={phoneNumberDialogIsVisible}
        closeDialog={() => setPhoneNumberDialogIsVisible(false)}
      />
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export {AuthProvider, useAuth, authReducer};
