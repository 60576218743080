import React from 'react';
import {useParams, useNavigate, Link as RouterLink} from 'react-router-dom';
import {Breadcrumbs, Link, Container, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import useYoungPersonCategories from '../Queries/useYoungPersonCategories';
import ProItemCard from './Components/ProItemCard';
import LoadingCard from './LoadingCard';

import {YOUNG_PERSON_CATEGORY_TOPIC_ICONS} from '../YoungPerson/transitionIcons';

const ProYoungPersonViewCategory = () => {
  const {data: categories, isLoading, error} = useYoungPersonCategories();
  const {categoryId} = useParams();
  const navigate = useNavigate();

  const category = categories?.find((category) => category.id === parseInt(categoryId)) ?? {
    name: '\xa0', // non-breaking space
  };
  const topicsWithIcons =
    category?.topics?.map((topic) => {
      return {
        ...topic,
        icon: YOUNG_PERSON_CATEGORY_TOPIC_ICONS[topic.key],
      };
    }) ?? [];

  return (
    <Container sx={{mt: 2}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to="/young-person-categories">
          Young People
        </Link>
        <Typography color="text.primary">{category.name}</Typography>
      </Breadcrumbs>
      <Typography variant="h2" textAlign="center" gutterBottom>
        {category.name}
      </Typography>
      <Grid container alignItems="stretch" spacing={2}>
        {isLoading ? (
          [...Array(9)].map((_, index) => (
            <Grid xs={12} md={4} key={index}>
              <LoadingCard />
            </Grid>
          ))
        ) : error ? (
          <div>Error: Unable to fetch transition categories. Please try again later.</div>
        ) : (
          <>
            <Grid container alignItems="stretch" spacing={2}>
              {topicsWithIcons.map((topic) => (
                <Grid xs={12} md={4} key={`topic-${topic.id}`}>
                  <ProItemCard item={topic} onClick={() => navigate(`item/${topic.id}`)} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default ProYoungPersonViewCategory;
