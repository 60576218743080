import React from 'react';
import {FormGroup, MenuItem, TextField} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

import {useAuth} from '../Auth/AuthProvider';
import ProAccountFieldEdit from './ProAccountFieldEdit';
const ProAccountSector = () => {
  const {authState, updateProfessionalProfile} = useAuth();
  const onSubmit = (data) => updateProfessionalProfile({...authState.user.profile, ...data});
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const {data: sectors, isLoading} = useQuery({
    queryKey: ['sectors'],
    queryFn: () => axios.get('/api/onboarding/sectors').then((res) => res.data),
    staleTime: Infinity,
  });
  return isLoading ? (
    <div>Loading</div>
  ) : (
    <ProAccountFieldEdit handleSubmit={handleSubmit} onSubmit={onSubmit} buttonDisabled={false}>
      <FormGroup>
        <TextField
          label="Sector"
          defaultValue={authState.user.profile.professional_sector_id}
          select
          sx={{textAlign: 'left'}}
          error={!!errors.professional_sector_id}
          helperText={errors.professional_sector_id && 'Please select your sector.'}
          {...register('professional_sector_id', {
            required: true,
          })}>
          {sectors.map((sector) => (
            <MenuItem key={`sector-${sector.id}`} value={sector.id}>
              {sector.name}
            </MenuItem>
          ))}
        </TextField>
      </FormGroup>
    </ProAccountFieldEdit>
  );
};

export default ProAccountSector;
