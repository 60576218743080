import React from 'react';

const HasExpectedLeavingDatePage = ({
  hasExpectedLeavingDate,
  setHasExpectedLeavingDate,
  autoFocus = true,
}) => (
  <>
    <h1 className="header-4 auth-text">Have you thought about an expected leaving date?</h1>
    <div className="body2 auth-text-sm">
      We use this to calculate your stage in the transitions path.
    </div>
    <div className="body2 auth-text-sm">
      If you don't have an expected leaving date yet, Compass will estimate one using your young
      person's date of birth and current school year to give you the best information.
    </div>
    <div className="yes-no-buttons">
      <button
        className={`question-button dark ${hasExpectedLeavingDate ? 'selected' : ''}`}
        name="has-leaving-date-yes"
        type="button"
        onClick={() => setHasExpectedLeavingDate(true)}>
        Yes
      </button>
      <button
        className={`question-button dark ${hasExpectedLeavingDate === false ? 'selected' : ''}`}
        name="has-leaving-date-no"
        type="button"
        onClick={() => setHasExpectedLeavingDate(false)}
        autoFocus={autoFocus}>
        No
      </button>
    </div>
  </>
);

export default HasExpectedLeavingDatePage;
