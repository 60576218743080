import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import {useMatomo} from '@jonkoops/matomo-tracker-react';

import {Link, Typography} from '@mui/material';

const CookieConsentBanner = () => {
  const {pushInstruction} = useMatomo();
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="compassCookieConsent"
      style={{background: '#12545c'}}
      buttonStyle={{
        borderRadius: '16px',
        padding: '6px 16px',
        cursor: 'pointer',
        backgroundColor: '#ed6f43',
        color: '#fff',
      }}
      declineButtonStyle={{
        borderRadius: '16px',
        padding: '6px 16px',
        cursor: 'pointer',
        backgroundColor: '#12545c',
        borderWidth: '1px',
        borderStyle: 'solid',
      }}
      declineButtonText="Decline"
      enableDeclineButton
      onAccept={() => {
        pushInstruction('setCookieConsentGiven');
        pushInstruction('rememberCookieConsentGiven');
      }}>
      <Typography variant="body2" sx={{color: 'white'}}>
        Compass uses cookies to collect analytics data to improve your experience. By clicking
        &apos;Accept&apos;, you consent to the use of cookies. You can change your cookie settings
        at any time. Read our{' '}
        <Link
          component={RouterLink}
          to="/privacy"
          color="secondary"
          target="_blank"
          rel="noreferrer">
          privacy notice
        </Link>{' '}
        to find out more or click &apos;Decline&apos; to opt-out of cookies.
      </Typography>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
