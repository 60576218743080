import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {SentryRoutes} from '../Sentry';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import NavBar from './ProNavBar';
import UnauthenticatedNavBar from '../Nav/UnauthenticatedNavBar';

import {useAuth} from '../Auth/AuthProvider';
import {OnboardingProvider} from '../Onboarding/OnboardingProvider';
import ProOnboarding from './Onboarding/ProOnboarding';
import {DIALOG_URL_PARAMS} from '../Util/constants';
import professionalLogo from '../img/logo/logo_professional.svg';
import professionalLogoDark from '../img/logo/logo_professional_dark.svg';
import {usePageTracking, MATOMO_COMPASS_APPLICATIONS} from '../MatomoAnalytics';

import ForgotPassword from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import Login from '../Auth/Login';
import ProLandingPage from './ProLandingPage';
import ProRegisterScreen from './ProRegisterScreen';
import ProHome from './ProHome';
import ProAccount from './ProAccount';

import ProTimeline from './ProTimeline';
import ContactUs from '../ContactUs';
import useDialogQuery from '../Util/useDialogQuery';
import GlossaryDialog from '../Glossary/GlossaryDialog';
import ProViewAction from './ProViewAction';
import ProParentCategories from './ProParentCategories';
import ProYoungPersonCategories from './ProYoungPersonCategories';
import ProYoungPersonViewCategory from './ProYoungPersonViewCategory';
import ProParentViewCategory from './ProParentViewCategory';
import ProViewTopic from './ProViewTopic';
import EditAccountScreen from '../Account/EditAccountScreen';
import ProAccountJobTitle from './ProAccountJobTitle';
import ProAccountSector from './ProAccountSector';
import ProAccountArea from './ProAccountArea';
import DeleteAccount from '../Account/DeleteAccount';
import ProPrivacyNotice from './ProPrivacyNotice';
import CookieConsentBanner from '../Util/CookieConsentBanner';
import ProEvaluationIndex from './Evaluation/ProEvaluationIndex';
import ProKeyDuties from './ProKeyDuties';
import ProKeyDutiesStage from './ProKeyDutiesStage';
import ProViewKeyDuty from './ProViewKeyDuty';
import StoreRedirectUrlForAuth from '../Util/StoreRedirectUrlForAuth';
import AppBannerMessage from '../Util/AppBannerMessage';

const App = () => {
  const {authState} = useAuth();

  usePageTracking(MATOMO_COMPASS_APPLICATIONS.PROFESSIONAL);

  return (
    <>
      <AppBannerMessage />
      {authState.isAuthenticated ? (
        <NavBar />
      ) : (
        <UnauthenticatedNavBar logoLight={professionalLogo} logoDark={professionalLogoDark} />
      )}
      <main>
        {authState.isCheckingAuth ? (
          <div>Loading</div>
        ) : authState.isAuthenticated ? (
          <AuthenticatedApp />
        ) : (
          <UnauthenticatedApp />
        )}
      </main>
      <CookieConsentBanner />
    </>
  );
};
const queryClient = new QueryClient();

const AuthenticatedApp = () => {
  const {dialogQuery} = useDialogQuery();
  return (
    <QueryClientProvider client={queryClient}>
      <OnboardingProvider Onboarding={ProOnboarding}>
        <SentryRoutes>
          <Route exact path="/" element={<ProHome />} />
          <Route exact path="/timeline" element={<ProTimeline />} />
          <Route exact path="/parent-categories" element={<ProParentCategories />} />
          <Route path="/parent-categories/:categoryId" element={<ProParentViewCategory />} />
          <Route path="/parent-categories/:categoryId/item/:actionId" element={<ProViewAction />} />
          <Route exact path="/young-person-categories" element={<ProYoungPersonCategories />} />
          <Route
            path="/young-person-categories/:categoryId"
            element={<ProYoungPersonViewCategory />}
          />
          <Route
            path="/young-person-categories/:categoryId/item/:topicId"
            element={<ProViewTopic />}
          />
          <Route path="/key-duties" element={<ProKeyDuties />} />
          <Route path="/key-duties/stage/:stageId" element={<ProKeyDutiesStage />} />
          <Route path="/key-duties/stage/:stageId/duty/:keyDutyId" element={<ProViewKeyDuty />} />
          <Route exact path="/my-account" element={<ProAccount />} />
          <Route path="/my-account/delete" element={<DeleteAccount />} />
          <Route
            path="/my-account/edit/*"
            element={
              <EditAccountScreen
                profileDetails={
                  <>
                    <Route path="job-title" element={<ProAccountJobTitle />} />
                    <Route path="sector" element={<ProAccountSector />} />
                    <Route path="area" element={<ProAccountArea />} />
                    <Route path="*" element={<Navigate to="/my-account" />} />
                  </>
                }
              />
            }
          />
          <Route path="/feedback/*" element={<ProEvaluationIndex />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy" element={<ProPrivacyNotice />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </SentryRoutes>
        {dialogQuery === DIALOG_URL_PARAMS.GLOSSARY && <GlossaryDialog />}
      </OnboardingProvider>
    </QueryClientProvider>
  );
};

const UnauthenticatedApp = () => {
  return (
    <SentryRoutes>
      <Route exact path="/" element={<ProLandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<ProRegisterScreen />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/privacy" element={<ProPrivacyNotice />} />
      <Route path="*" element={<StoreRedirectUrlForAuth />} />
    </SentryRoutes>
  );
};

export default App;
