import React from 'react';
import {useForm} from 'react-hook-form';
import {useQueries} from '@tanstack/react-query';
import axios from 'axios';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormHelperText,
  Stack,
  MenuItem,
  TextField,
  CircularProgress,
  Radio,
  RadioGroup,
  Typography,
  Button,
  Container,
  Paper,
  Box,
} from '@mui/material';

import {useOnboarding} from '../../Onboarding/OnboardingProvider';
import useHealthBoardAreas from '../../Queries/useHealthBoardAreas';
import {PRO_REGION_TYPES} from '../../Util/constants';

export default function ProOnboardingAboutYou() {
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm();
  const selectedRegionType = watch('region_type');

  const {submitProOnboardingData} = useOnboarding();
  const onSubmit = (data) => submitProOnboardingData(data);

  const healthBoardAreasQuery = useHealthBoardAreas();

  const results = useQueries({
    queries: [
      {
        queryKey: ['sectors'],
        queryFn: () => axios.get('/api/onboarding/sectors').then((res) => res.data),
        staleTime: Infinity,
      },
      {
        queryKey: ['localAuthorities'],
        queryFn: () => axios.get('/api/local-authorities').then((res) => res.data),
        staleTime: Infinity,
      },
    ],
  });
  if (results.some((result) => result.isLoading)) return 'Loading...';
  if (results.some((result) => result.isError)) return 'An error has occurred.';

  const [sectors, localAuthorities] = results.map((result) => result.data);
  // sort sectors alphabetically by sector.name
  let sortedSectors = [...sectors];
  sortedSectors.sort((a, b) => (a.name > b.name ? 1 : -1));
  return (
    <div className="center-content">
      <Container maxWidth="md">
        <Paper sx={{mt: 4, p: 4, borderRadius: 5}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={{mb: 3}}>
              <Stack spacing={3}>
                <Stack spacing={2} sx={{textAlign: 'center'}}>
                  <Typography variant="h3">About you</Typography>
                  <Typography>
                    Let us know a little bit about you so that we can tailor your experience:
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <FormGroup>
                    <TextField
                      label="Job Title"
                      {...register('job_title', {required: true})}
                      error={!!errors.job_title}
                      helperText={errors.job_title && 'Please enter your job title.'}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      label="Sector"
                      defaultValue={''}
                      select
                      {...register('professional_sector_id', {required: true})}
                      error={!!errors.professional_sector_id}
                      helperText={errors.professional_sector_id && 'Please select your sector.'}>
                      {sortedSectors.map((sector) => (
                        <MenuItem key={`sector-${sector.id}`} value={sector.id}>
                          {sector.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormGroup>
                </Stack>
                <Stack spacing={2}>
                  <FormGroup>
                    <FormLabel id="region-radio-buttons-group-label" sx={{textAlign: 'center'}}>
                      Please select the type of region that best reflects where you work:
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="region-radio-buttons-group"
                      name="region-radio-buttons-group">
                      {PRO_REGION_TYPES.map((regionType) => (
                        <FormControlLabel
                          key={`region-type-${regionType}`}
                          value={regionType}
                          control={<Radio {...register('region_type', {required: true})} />}
                          label={regionType}
                        />
                      ))}
                    </RadioGroup>
                    <FormHelperText error={!!errors.region_type}>
                      {errors.region_type && 'Please select a region type.'}
                    </FormHelperText>
                  </FormGroup>
                  {selectedRegionType === 'Local Authority' && (
                    <FormGroup>
                      <TextField
                        label="Local Authority"
                        defaultValue={''}
                        select
                        {...register('local_authority_id', {required: true})}
                        error={!!errors.local_authority_id}
                        helperText={
                          errors.local_authority_id && 'Please select your local authority.'
                        }>
                        {localAuthorities.map((localAuthority) => (
                          <MenuItem
                            key={`local-authority-${localAuthority.id}`}
                            value={localAuthority.id}>
                            {localAuthority.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormGroup>
                  )}
                  {selectedRegionType === 'Health Board' &&
                    (!healthBoardAreasQuery.isLoading ? (
                      <FormGroup>
                        <TextField
                          label="Health Board Area"
                          defaultValue={''}
                          select
                          error={!!errors.health_board_area_id}
                          helperText={
                            errors.health_board_area_id && 'Please select your health board area.'
                          }
                          {...register('health_board_area_id', {required: true})}>
                          {healthBoardAreasQuery.data.map((healthBoardArea) => (
                            <MenuItem
                              key={`health-board-area-${healthBoardArea.id}`}
                              value={healthBoardArea.id}>
                              {healthBoardArea.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormGroup>
                    ) : (
                      <CircularProgress />
                    ))}
                </Stack>
              </Stack>
            </FormControl>
            <Box display="flex" justifyContent={'center'}>
              <Button variant="contained" color="secondary" type="submit">
                Finish
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </div>
  );
}
