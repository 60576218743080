import React from 'react';
import Dialog from '@mui/material/Dialog';
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

import {onCloseUnlessBackdropClick} from './Util/helpers';
import {useAuth} from './Auth/AuthProvider';

const LogoutConfirmationDialog = ({isVisible, closeDialog}) => {
  const {logout} = useAuth();
  return (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}>
      <DialogTitle>Confirm Logout</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to logout?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={() => {
            closeDialog();
            logout();
          }}>
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutConfirmationDialog;
